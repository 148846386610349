import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const header_store = {
  namespaced: true,
  state: {
    header_option: 'Home',
  },
  mutations: {
    setHeaderOption(state, value) {
      state.header_option = value;
    },
  },
  actions: {
    logout_api() {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.logout_api)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default header_store;
