import { createApp } from 'vue';
import App from './App.vue';
import router from './routing/route';
import store from './store/store';
import './apis/axios';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ToastPlugin);
app.mount('#app');
