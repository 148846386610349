<template>
  <div
    @click="
      detail_navigation(activity.activity.id, activity.activity.entity)
    "
    class="shadow-md border-1 border-borderColor py-10 px-5 rounded-3xl bg-white cursor-pointer"
  >
    <div class="flex sm:max-lg:flex-col max-[640px]:flex-col">
      <div
        class="flex flex-col lg:w-[35%] max-[640px]:w-full sm:max-lg:items-center max-[640px]:items-center"
      >
        <div
          class="relative xl:h-[260px] lg:h-[161px] max-[1023px]:h-[285px] max-[1023px]:w-[210px] border-1 border-borderColor rounded-xl"
        >
          <img
            :src="activity.activity.image"
            :alt="activity.activity.name"
            class="rounded-xl absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto p-2"
          />
        </div>
        <div class="flex justify-center mt-3 max-[560px]:flex-col">
          <div class="flex items-center">
            <MediaLikeComponent :identify_media_data="activity.activity" />

            <span class="text-xl mx-3">Like</span>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col lg:ml-5 lg:w-[65%] max-[640px]:w-full max-[1023px]:mt-5"
      >
        <div class="h-[400px] overflow-y-scroll">
          <h2 class="text-[28px] font-bold mb-2 max-[640px]:text-[24px]">
            {{ activity.activity.name }}
          </h2>
          <!-- <p class="text-sm mt-3 h-[100px] overflow-y-auto pr-3">
                    <template v-if="activity.activity.description">
                        <p class="py-1"><b>Description:</b></p>
                        {{ activity.activity.description }}
                    </template>
<template v-if="activity.description">
                        <p class="py-1"><b>Title Specific Description:</b></p>
                        {{ activity.description }}
                    </template>
</p> -->
          <template v-if="activity.activity.description">
            <p class="py-1">
              <span><b>Description:</b></span>
            </p>
            <p class="text-sm mt-1 h-[100px] overflow-y-auto pr-3">
              {{ activity.activity.description }}
            </p>
          </template>
          <template v-if="activity.description">
            <p class="py-1 mt-1">
              <span><b>Title Specific Description:</b></span>
            </p>
            <p class="text-sm mt-1 h-[100px] overflow-y-auto pr-3">
              {{ activity.description }}
            </p>
          </template>
          <p class="text-black my-2">
            <span class="text-sm font-semibold"
              >Minimum Participants:&nbsp;</span
            >
            <span class="text-sm opacity-40">{{
              activity.activity.no_of_participants
            }}</span>
          </p>
          <template v-if="activity.activity.places.length > 0">
            <div class="text-black my-2 flex">
              <span class="text-sm font-semibold mr-2">Location:</span>
              <div class="flex flex-wrap gap-2">
                <template
                  v-for="(place, index) in activity.activity.places"
                  :key="index"
                >
                  <div
                    class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1"
                  >
                    <span class="text-[10px] text-blue ml-1">{{ place }}</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
export default {
  name: 'ActivitiesCardComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    activity: {
      type: Object,
    },
  },
  data() {
    return {
      isLike: this.activity.activity.like,
    };
  },
  methods: {
    detail_navigation(id, component) {
      specific_page_routing(this.$router, id, component)
    },
  }
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
