<template>
  <span
    @click.stop="set_like_dislike"
    class="flex justify-center items-center bg-white rounded-full p-2"
  >
    <template v-if="like_response">
      <img src="../../images/fill-heart.svg" alt="Liked" class="" />
    </template>
    <template v-else>
      <img src="../../images/empty-heart.svg" alt="Liked" class="" />
    </template>
  </span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { logout_messages } from '@/common_variables/logout_messages';
export default {
  name: 'FavCardIcon',
  props: {
    card_data: {
      type: Object,
      required: true,
    },
    related_media_param: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      like_response: null,
      change_value: null,
    };
  },

  mounted() {
    this.like_response = this.card_data.like;
    this.change_value = this.card_data.like;
  },
  methods: {
    ...mapActions('fav_card_icon_store', ['favourite_data_api']),
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    set_like_dislike() {
      localStorage.getItem('token');
      if (
        localStorage.getItem('token') === undefined ||
        localStorage.getItem('token') === null ||
        localStorage.getItem('token') === ''
      ) {
        this.set_show_login_popup(true);
        this.set_msg(logout_messages.fav_message);
      } else {
        console.log("related_media is: ", this.related_media_param)
        this.like_response = !this.like_response;
        this.favourite_data_api({
          id: this.card_data.id,
          like: this.like_response,
          entity: this.card_data.entity,
          fav_related_media: this.related_media_param
        });
        this.change_value = this.like_response;
        this.$toast.open({
          message:
            (this.card_data.title
              ? this.card_data.title
              : this.card_data.name) +
            (this.like_response
              ? ' Added to Favourite'
              : ' Removed from Favourite'),
          type: 'info',
          duration: 1000 * 5,
          dismissible: true,
        });
      }
    },
  },
};
</script>

<style></style>
