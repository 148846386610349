import $ from 'jquery';

function intializeResultMediaSlider() {
  $('.result-media-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.previous-result-media').click(function () {
    $('.result-media-slider').slick('slickPrev');
  });

  $('.next-result-media').click(function () {
    $('.result-media-slider').slick('slickNext');
  });
}

function initializeOtherAuthorsSlider() {
  $('.other-authors-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.previous-other-author').click(function () {
    $('.other-authors-slider').slick('slickPrev');
  });

  $('.next-other-author').click(function () {
    $('.other-authors-slider').slick('slickNext');
  });
}

export function initializeSliders() {
  intializeResultMediaSlider();
  initializeOtherAuthorsSlider();
}
