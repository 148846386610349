// slider.js

import $ from 'jquery';
import Swiper from 'swiper'; 
import 'swiper/swiper-bundle.css';  // Ensure CSS is imported

function intializeRecentSlider() {
  var swiper = new Swiper('.recently-viewed-slider', {
    slidesPerView: 3,      // Equivalent to `slidesToShow: 4`
    spaceBetween: 10,      // Add space between slides if needed
    loop: false,           // Equivalent to `infinite: false`
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1280: {
        slidesPerView: 3,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      767: {
        slidesPerView: 2,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      320: {
        slidesPerView: 1,   // Equivalent to `slidesToShow: 1`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
    }
  });

  $('.previous-viewed').click(function () {
    swiper.slidePrev();  // Moves to the previous slide
  });

  $('.next-viewed').click(function () {
    swiper.slideNext();  // Moves to the next slide
  });
}

function intializeTrendingSlider() {

  var swiper = new Swiper('.trending-media-slider', {
    slidesPerView: 4,      // Equivalent to `slidesToShow: 4`
    spaceBetween: 10,      // Add space between slides if needed
    loop: false,           // Equivalent to `infinite: false`
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1280: {
        slidesPerView: 4,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      767: {
        slidesPerView: 2,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      320: {
        slidesPerView: 1,   // Equivalent to `slidesToShow: 1`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
    }
  });

  $('.previous-trending-media').click(function () {
    swiper.slidePrev();  // Moves to the previous slide
  });
  
  $('.next-trending-media').click(function () {
    swiper.slideNext();  // Moves to the next slide
  });
}

function intializeFeaturedSlider() {
 
  var swiper = new Swiper('.featured-media-slider', {
    slidesPerView: 4,      // Equivalent to `slidesToShow: 4`
    spaceBetween: 10,      // Add space between slides if needed
    loop: false,           // Equivalent to `infinite: false`
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1280: {
        slidesPerView: 4,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      767: {
        slidesPerView: 2,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      320: {
        slidesPerView: 1,   // Equivalent to `slidesToShow: 1`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
    }
  });
  
  $('.previous-feature-media').click(function () {
    swiper.slidePrev();  // Moves to the previous slide
  });
  
  $('.next-feature-media').click(function () {
    swiper.slideNext();  // Moves to the next slide
  });
}


export function initializeFavouriteSlider() {
  $('.activity-media-slider').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.previous-activity').click(function () {
    $('.activity-media-slider').slick('slickPrev');
  });

  $('.next-activity').click(function () {
    $('.activity-media-slider').slick('slickNext');
  });
}

export function initializeNewAllFavouriteSlider() {

  var swiper = new Swiper('.new-all-favorites-slider', {
    slidesPerView: 4,      // Equivalent to `slidesToShow: 4`
    spaceBetween: 10,      // Add space between slides if needed
    loop: false,           // Equivalent to `infinite: false`
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1280: {
        slidesPerView: 2,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      767: {
        slidesPerView: 2,   // Equivalent to `slidesToShow: 2`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
      320: {
        slidesPerView: 1,   // Equivalent to `slidesToShow: 1`
        slidesPerGroup: 1,  // Equivalent to `slidesToScroll: 1`
      },
    }
  });

  $('.previous-newfavorites').click(function () {
    swiper.slidePrev();  // Moves to the previous slide
  });
  
  $('.next-newfavorites').click(function () {
    swiper.slideNext();  // Moves to the next slide
  });

}

export function initializeSliders() {
  intializeRecentSlider();
  intializeTrendingSlider();
  intializeFeaturedSlider();
  initializeNewAllFavouriteSlider();
}

export default {
  initializeSliders,
  initializeFavouriteSlider,
};
