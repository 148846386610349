import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const create_new_media_store = {
  namespaced: true,
  state: {
    media_type_options: null,
    target_audience_options: null,
    media_format_options: null,
  },
  mutations: {
    set_media_type_options(state, data) {
      state.media_type_options = data;
    },
    set_target_audience_options(state, data) {
      state.target_audience_options = data;
    },
    set_media_format_options(state, data) {
      state.media_format_options = data;
    },
  },
  actions: {
    get_media_type_options_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.media_type_options)
          .then((response) => {
            commit('set_media_type_options', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    add_target_audience_options_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.target_audience_options)
          .then((response) => {
            commit('set_target_audience_options', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    get_media_format_options_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.media_format_options}?media_type=${payload.media_type}`)
          .then((response) => {
            commit('set_media_format_options', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    add_media_form_api(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(APIs.media_form_api, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default create_new_media_store;
