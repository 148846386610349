import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const engage_store = {
  namespaced: true,
  state: {
    challange_result: null,
    challange_result_list: [],
  },
  mutations: {
    set_challange_result(state, data) {
      state.challange_result = data;
    },
    set_challange_result_list(state, data) {
      state.challange_result_list = state.challange_result_list.concat(data);
    },
    reset_challange_result_list(state) {
      state.challange_result_list = [];
    },
  },
  actions: {
    challange_show_result_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const apiUrl = `${APIs.challange_api.replace('{id}', payload.id)}?per_page=4&page=${payload.page}`;
        console.log('api is: ', apiUrl);
        axios
          .get(apiUrl)
          .then((response) => {
            commit('set_challange_result', response.data.result);
            commit('set_challange_result_list', response.data.result.results);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting set_challange_result API:', error);
            reject(error);
          });
      });
    },
  },
};

export default engage_store;
