<div class="lg:h-screen lg:overflow-hidden">
  <div class="w-full px-5 mx-auto">
    <!-- <p>{{$store.state.create_account.user_name}}</p>
  <p>{{$store.state.create_account.age}}</p>
  <p>{{$store.state.create_account.email}}</p>
  <p>{{$store.state.create_account.password}}</p> -->
    <div class="flex justify-center">
      <div class="lg:w-1/2 hidden sm:hidden md:hidden lg:block lg:h-screen">
        <img
          src="../../images/create-account-banner.jpg"
          alt="Create Account Banner"
          class="max-h-full w-full object-contain"
        />
      </div>
      <div
        class="flex flex-col justify-center lg:w-1/2 w-full sm:w-full md:w-full p-5"
      >
        <p class="text-right">
          Do you have account?
          <a
            @click.prevent="login_account_navigate()"
            href="/"
            class="text-blue font-bold"
            >Sign in</a
          >
        </p>
        <h1 class="font-bold text-blueDark text-4xl mt-5">Create Account</h1>
        <p class="text-blueDark text-sm font-normal mt-5">
          Follow the instructions to make it easier to register and you will be
          able to explore inside.
        </p>
        <!-- Create Account Start -->
        <div class="create-account-form">
          <form>
            <div class="relative mt-5">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute mt-6 ml-7 pointer-events-none"
              >
                <path
                  opacity="0.5"
                  d="M16.9515 15.3573C16.5315 14.3624 15.9219 13.4586 15.1568 12.6964C14.3939 11.932 13.4903 11.3225 12.4958 10.9016C12.4869 10.8972 12.478 10.8949 12.4691 10.8905C13.8563 9.88845 14.7582 8.25625 14.7582 6.41474C14.7582 3.36411 12.2865 0.892427 9.23585 0.892427C6.18522 0.892427 3.71354 3.36411 3.71354 6.41474C3.71354 8.25625 4.61537 9.88845 6.00263 10.8927C5.99372 10.8972 5.98481 10.8994 5.97591 10.9038C4.97833 11.3247 4.08318 11.9281 3.31495 12.6986C2.55053 13.4614 1.94107 14.365 1.5202 15.3596C1.10675 16.3332 0.883759 17.3771 0.863313 18.4347C0.862719 18.4584 0.866888 18.4821 0.875573 18.5042C0.884259 18.5263 0.897287 18.5465 0.913888 18.5635C0.930489 18.5805 0.950329 18.5941 0.972237 18.6033C0.994145 18.6125 1.01768 18.6173 1.04145 18.6173H2.3775C2.47547 18.6173 2.55341 18.5393 2.55564 18.4436C2.60017 16.7245 3.29046 15.1146 4.51071 13.8944C5.77327 12.6318 7.45001 11.9371 9.23585 11.9371C11.0217 11.9371 12.6984 12.6318 13.961 13.8944C15.1812 15.1146 15.8715 16.7245 15.9161 18.4436C15.9183 18.5416 15.9962 18.6173 16.0942 18.6173H17.4303C17.454 18.6173 17.4776 18.6125 17.4995 18.6033C17.5214 18.5941 17.5412 18.5805 17.5578 18.5635C17.5744 18.5465 17.5874 18.5263 17.5961 18.5042C17.6048 18.4821 17.609 18.4584 17.6084 18.4347C17.5861 17.3703 17.3657 16.3349 16.9515 15.3573ZM9.23585 10.2447C8.21378 10.2447 7.25183 9.84615 6.52814 9.12246C5.80445 8.39877 5.40586 7.43681 5.40586 6.41474C5.40586 5.39267 5.80445 4.43072 6.52814 3.70703C7.25183 2.98334 8.21378 2.58475 9.23585 2.58475C10.2579 2.58475 11.2199 2.98334 11.9436 3.70703C12.6673 4.43072 13.0658 5.39267 13.0658 6.41474C13.0658 7.43681 12.6673 8.39877 11.9436 9.12246C11.2199 9.84615 10.2579 10.2447 9.23585 10.2447Z"
                  fill="#03014C"
                />
              </svg>
              <input
                v-model="user_name"
                type="text"
                placeholder="Full Name"
                class="bg-inputColor rounded-full pl-16 pr-5 py-6 block w-full focus:border-blueDark focus-visible:border-blueDark focus:outline-blueDark"
              />
            </div>
            <div class="relative mt-5">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute mt-6 ml-7 pointer-events-none"
              >
                <path
                  opacity="0.5"
                  d="M16.9515 15.3573C16.5315 14.3624 15.9219 13.4586 15.1568 12.6964C14.3939 11.932 13.4903 11.3225 12.4958 10.9016C12.4869 10.8972 12.478 10.8949 12.4691 10.8905C13.8563 9.88845 14.7582 8.25625 14.7582 6.41474C14.7582 3.36411 12.2865 0.892427 9.23585 0.892427C6.18522 0.892427 3.71354 3.36411 3.71354 6.41474C3.71354 8.25625 4.61537 9.88845 6.00263 10.8927C5.99372 10.8972 5.98481 10.8994 5.97591 10.9038C4.97833 11.3247 4.08318 11.9281 3.31495 12.6986C2.55053 13.4614 1.94107 14.365 1.5202 15.3596C1.10675 16.3332 0.883759 17.3771 0.863313 18.4347C0.862719 18.4584 0.866888 18.4821 0.875573 18.5042C0.884259 18.5263 0.897287 18.5465 0.913888 18.5635C0.930489 18.5805 0.950329 18.5941 0.972237 18.6033C0.994145 18.6125 1.01768 18.6173 1.04145 18.6173H2.3775C2.47547 18.6173 2.55341 18.5393 2.55564 18.4436C2.60017 16.7245 3.29046 15.1146 4.51071 13.8944C5.77327 12.6318 7.45001 11.9371 9.23585 11.9371C11.0217 11.9371 12.6984 12.6318 13.961 13.8944C15.1812 15.1146 15.8715 16.7245 15.9161 18.4436C15.9183 18.5416 15.9962 18.6173 16.0942 18.6173H17.4303C17.454 18.6173 17.4776 18.6125 17.4995 18.6033C17.5214 18.5941 17.5412 18.5805 17.5578 18.5635C17.5744 18.5465 17.5874 18.5263 17.5961 18.5042C17.6048 18.4821 17.609 18.4584 17.6084 18.4347C17.5861 17.3703 17.3657 16.3349 16.9515 15.3573ZM9.23585 10.2447C8.21378 10.2447 7.25183 9.84615 6.52814 9.12246C5.80445 8.39877 5.40586 7.43681 5.40586 6.41474C5.40586 5.39267 5.80445 4.43072 6.52814 3.70703C7.25183 2.98334 8.21378 2.58475 9.23585 2.58475C10.2579 2.58475 11.2199 2.98334 11.9436 3.70703C12.6673 4.43072 13.0658 5.39267 13.0658 6.41474C13.0658 7.43681 12.6673 8.39877 11.9436 9.12246C11.2199 9.84615 10.2579 10.2447 9.23585 10.2447Z"
                  fill="#03014C"
                />
              </svg>
              <input
                v-model="age"
                type="number"
                placeholder="Age"
                class="bg-inputColor rounded-full pl-16 pr-5 py-6 block w-full focus:border-blueDark focus-visible:border-blueDark focus:outline-blueDark"
              />
            </div>
            <div class="relative mt-5">
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute mt-6 ml-7 pointer-events-none"
              >
                <g opacity="0.5">
                  <path
                    d="M1.68964 7.31308C1.68987 7.163 1.72862 7.01549 1.80217 6.88466C1.87572 6.75384 1.98161 6.64408 2.10972 6.56588L9.58257 1.9985L17.0554 6.56588C17.1835 6.64408 17.2894 6.75384 17.363 6.88466C17.4365 7.01549 17.4753 7.163 17.4755 7.31308V16.0304C17.4755 16.263 17.3831 16.486 17.2186 16.6505C17.0542 16.815 16.8311 16.9074 16.5985 16.9074H2.56663C2.33404 16.9074 2.11097 16.815 1.9465 16.6505C1.78203 16.486 1.68964 16.263 1.68964 16.0304V7.31308Z"
                    stroke="#03014C"
                    stroke-width="1.75399"
                  />
                  <path
                    d="M1.68961 7.699L9.58254 12.5225L17.4755 7.699"
                    stroke="#03014C"
                    stroke-width="1.75399"
                    stroke-linecap="round"
                  />
                </g>
              </svg>
              <input
                v-model="email"
                type="text"
                placeholder="Email"
                class="bg-inputColor rounded-full pl-16 pr-5 py-6 block w-full focus:border-blueDark focus-visible:border-blueDark focus:outline-blueDark"
              />
            </div>
            <div class="relative mt-5">
              <svg
                width="15"
                height="19"
                viewBox="0 0 15 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute mt-6 ml-7 pointer-events-none"
              >
                <path
                  opacity="0.5"
                  d="M7.58259 14.0668C8.54728 14.0668 9.33658 13.2775 9.33658 12.3128C9.33658 11.3481 8.54728 10.5589 7.58259 10.5589C6.6179 10.5589 5.82861 11.3481 5.82861 12.3128C5.82861 13.2775 6.6179 14.0668 7.58259 14.0668ZM12.8445 6.17389H11.9676V4.41991C11.9676 1.99941 10.0031 0.0349426 7.58259 0.0349426C5.16209 0.0349426 3.19763 1.99941 3.19763 4.41991H4.86391C4.86391 2.92025 6.08293 1.70123 7.58259 1.70123C9.08225 1.70123 10.3013 2.92025 10.3013 4.41991V6.17389H2.32064C1.35594 6.17389 0.56665 6.96318 0.56665 7.92788V16.6978C0.56665 17.6625 1.35594 18.4518 2.32064 18.4518H12.8445C13.8092 18.4518 14.5985 17.6625 14.5985 16.6978V7.92788C14.5985 6.96318 13.8092 6.17389 12.8445 6.17389ZM12.8445 16.6978H2.32064V7.92788H12.8445V16.6978Z"
                  fill="#03014C"
                />
              </svg>
              <input
                v-model="password"
                :type="passwordFieldType"
                placeholder="Password"
                class="bg-inputColor rounded-full pl-16 pr-60 py-6 block w-full focus:border-blueDark focus-visible:border-blueDark focus:outline-blueDark"
              />
              <a
                @click="togglePasswordVisibility"
                class="absolute right-5 bottom-5 cursor-pointer"
              >
                <svg
                  width="28"
                  height="24"
                  viewBox="0 0 28 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M13.8853 15.358C14.7773 15.358 15.6327 15.0007 16.2634 14.3647C16.8942 13.7286 17.2485 12.866 17.2485 11.9665C17.2485 11.8672 17.244 11.7688 17.2356 11.6716L13.5928 15.345C13.6892 15.3535 13.7865 15.358 13.8853 15.358ZM25.0176 1.47589L23.7347 0.183492C23.6897 0.138095 23.6286 0.112595 23.5649 0.112595C23.5012 0.112595 23.4402 0.138095 23.3951 0.183492L20.1124 3.49474C18.3014 2.56148 16.2658 2.09484 14.0054 2.09484C8.23384 2.09484 3.92771 5.12599 1.08698 11.1883C0.972793 11.4321 0.913574 11.6984 0.913574 11.968C0.913574 12.2377 0.972793 12.504 1.08698 12.7478C2.22207 15.1588 3.58888 17.0912 5.18742 18.5451L2.01066 21.7474C1.96565 21.7928 1.94036 21.8544 1.94036 21.9186C1.94036 21.9828 1.96565 22.0444 2.01066 22.0898L3.29259 23.3825C3.33764 23.4279 3.39872 23.4534 3.46241 23.4534C3.52609 23.4534 3.58717 23.4279 3.63222 23.3825L25.0176 1.81868C25.0399 1.79618 25.0576 1.76946 25.0697 1.74005C25.0818 1.71064 25.088 1.67912 25.088 1.64729C25.088 1.61545 25.0818 1.58393 25.0697 1.55452C25.0576 1.52511 25.0399 1.49839 25.0176 1.47589ZM8.60019 11.9665C8.60011 11.046 8.83647 10.1411 9.28625 9.34009C9.73604 8.53906 10.3839 7.86916 11.1667 7.39566C11.9496 6.92216 12.8407 6.6612 13.7533 6.6382C14.6658 6.61521 15.5688 6.83097 16.3741 7.26445L14.9141 8.73672C14.3253 8.54661 13.6959 8.52371 13.095 8.67055C12.4942 8.81738 11.945 9.12828 11.5079 9.56913C11.0707 10.01 10.7624 10.5637 10.6168 11.1696C10.4712 11.7756 10.4939 12.4102 10.6824 13.004L9.22239 14.4762C8.81268 13.7043 8.59892 12.8421 8.60019 11.9665Z"
                    fill="#03014C"
                  />
                  <path
                    opacity="0.3"
                    d="M27.4117 11.3353C26.3323 9.13296 25.0476 7.33716 23.5575 5.94785L19.1374 10.2302C19.51 11.1737 19.5926 12.2016 19.3752 13.1896C19.1578 14.1776 18.6497 15.0836 17.9124 15.7979C17.175 16.5122 16.2399 17.0044 15.22 17.215C14.2001 17.4257 13.1391 17.3456 12.1651 16.9847L8.41602 20.6167C10.1553 21.3964 12.0899 21.7863 14.2197 21.7863C20.1135 21.7863 24.5108 18.8126 27.4117 12.8652C27.5284 12.626 27.5889 12.3648 27.5889 12.1002C27.5889 11.8357 27.5284 11.5745 27.4117 11.3353Z"
                    fill="#03014C"
                  />
                </svg>
              </a>
            </div>
            <button
              type="submit"
              class="bg-blue block w-full rounded-full text-white py-6 mt-5 text-center font-medium text-xl"
              @click.prevent="set_user_info()"
            >
              Create Account
            </button>
          </form>
        </div>
        <!-- Create Account End -->
        <!-- <div class="flex justify-between flex-row max-[440px]:flex-col">
        <button
          type="button"
          class="border-solid border-silver border-1 rounded-full px-4 sm:px-6 pt-5 pb-4 mt-6 mr-5 text-center w-full flex justify-center justify-items-center hover:bg-silver transition ease-in-out duration-300"
        >
          <img src="../../images/google.svg" alt="Google" />
          <span class="ml-3 font-medium text-blueDark text-sm">Google</span>
        </button>
        <button
          type="button"
          class="border-solid border-silver border-1 rounded-full px-4 sm:px-6 py-4 mt-6 text-center w-full flex justify-center justify-items-center hover:bg-silver transition ease-in-out duration-300"
        >
          <img src="../../images/facebook.svg" alt="Facebook" />
          <span class="ml-3 font-medium text-blueDark text-sm mt-1"
            >Facebook</span
          >
        </button>
      </div> -->
        <div
          class="flex justify-center justify-items-center flex-row max-[360px]:flex-col max-[360px]:text-center mt-6"
        >
          <a
            href="https://www.in-formation.io/terms-of-use"
            class="text-blue mr-5 max-[360px]:mr-0 max-[360px]:mb-4 text-base relative after:bg-blue after:absolute after:h-px after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer"
            >Terms & Conditions</a
          >
          <a
            href="https://www.in-formation.io/privacy-policy"
            class="text-blue text-base relative after:bg-blue after:absolute after:h-px after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
