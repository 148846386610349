import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const experience_store = {
  namespaced: true,
  state: {
    exp_status: null,
    exp_progress: null,
    experience_data: null,
  },
  mutations: {

    set_experience_data(state, data) {
      state.experience_data = data;
    },
    set_exp_status(state, data) {
      state.exp_status = data;
    },
    set_exp_progress(state, data) {
      state.exp_progress = data;
    },

    set_experience_data_null(state) {
      state.exp_status = null;
      state.exp_progress = null;
      state.experience_data = null;
    },
  },
  actions: {
    post_experience_data_api(_, payload) {
      console.log('post_experience_data_api payload:', payload);
      let media_data_id = payload.id;
      delete payload.id;
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${APIs.update_experience_api.replace('{id}', media_data_id)}`,
            payload,
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    get_experience_data_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.experience_api.replace('{id}', payload.id)}`)
          .then((response) => {
            commit('set_experience_data', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    get_exp_status_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.exp_status}?media_type=${payload.media_type}`)
          .then((response) => {
            commit('set_exp_status', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    get_exp_progress_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.exp_progress}?media_type=${payload.media_type}`)
          .then((response) => {
            commit('set_exp_progress', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default experience_store;
