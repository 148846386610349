<template>
  <div
    @click="
      detail_navigation(
        character.character.id,
        character.character.entity,
      )
    "
    class="shadow-md border-1 border-borderColor py-10 px-5 rounded-3xl cursor-pointer"
  >
    <div
      class="flex sm:max-lg:flex-col max-[640px]:flex-col h-[400px] overflow-y-scroll"
    >
      <div
        class="flex flex-col lg:w-[35%] max-[640px]:w-full sm:max-lg:items-center max-[640px]:items-center"
      >
        <div
          class="relative xl:h-[260px] lg:h-[161px] max-[1023px]:h-[285px] max-[1023px]:w-[210px] border-1 border-borderColor rounded-xl"
        >
          <img
            :src="character.character.image"
            :alt="character.character.name"
            class="rounded-xl absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto p-2"
          />
        </div>
        <div class="flex justify-center max-[560px]:flex-col mt-3">
          <div class="flex items-center">
            <!-- Replace with grey icon if not isLike-->
            <MediaLikeComponent :identify_media_data="character.character" />
            <span class="text-xl mx-3">Like</span>
          </div>
        </div>
        <template v-if="character.character.additional_resources.length > 0">
          <button
            class="text-sm text-white bg-blue font-medium py-2 px-2 mt-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
            @click.stop="additionalSources($event, character.id)"
          >
            {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
          </button>
          <ul
            class="flex flex-col mt-5 gap-5 sm:text-center max-[640px]:text-center hidden"
            :id="'additionalSources-' + character.id"
          >
            <template
              v-for="(add_src, index) in character.character
                .additional_resources"
              :key="index"
            >
              <li>
                <span class="text-black underline">
                  <a
                    v-if="add_src.link"
                    :href="add_src.link"
                    target="_blank"
                    @click.stop=""
                    >{{ add_src.description }}</a
                  ></span
                >
              </li>
            </template>
          </ul>
        </template>
      </div>
      <div
        class="flex flex-col lg:ml-5 lg:w-[65%] max-[640px]:w-full max-[1023px]:mt-5"
      >
        <h2 class="text-[28px] font-bold mb-2 max-[640px]:text-[24px]">
          {{ character.character.name }}
        </h2>
        <template v-if="character.character.groups.length > 0">
          <p class="text-black">
            <span>ROLES:&nbsp;</span>
            <span class="opacity-40">{{
              character.character.groups.join(', ')
            }}</span>
          </p>
        </template>
        <template v-if="character.character.description">
          <p class="py-1">
            <span><b>Description:</b></span>
          </p>
          <p class="text-sm mt-1 pr-3">
            {{ character.character.description }}
          </p>
        </template>
        <template v-if="character.description">
          <p class="py-1 mt-1">
            <span><b>Title Specific Description:</b></span>
          </p>
          <p class="text-sm mt-1 pr-3">
            {{ character.description }}
          </p>
        </template>
        <div class="flex max-[376px]:flex-col my-5">
          <div
            class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1 mr-3 max-[376px]:mb-3 max-[376px]:w-full"
          >
            <span
              class="text-xs"
              :class="isReal ? 'text-blue ml-1' : 'text-black opacity-20'"
              >Real</span
            >
            <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
            <span
              class="text-xs"
              :class="isFiction ? 'text-blue ml-1' : 'text-black opacity-20'"
              >Fiction</span
            >
          </div>
          <div
            class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1"
          >
            <span
              class="text-xs"
              :class="isNative ? 'text-blue mr-1' : 'text-black opacity-20'"
              >Native
            </span>
            <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
            <span
              class="text-xs"
              :class="isMentioned ? 'text-blue mr-1' : 'text-black opacity-20'"
              >Mentioned</span
            >
          </div>
        </div>
        <template v-if="character.character.traits.length > 0">
          <h4 class="text-xs font-bold">Character Traits</h4>
          <div class="flex flex-wrap my-5">
            <template
              v-for="(trait, index) in character.character.traits"
              :key="index"
            >
              <div
                class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1 mr-3 mb-2"
              >
                <span class="text-xs text-blue ml-1">{{ trait }}</span>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
export default {
  components: {
    MediaLikeComponent,
  },
  data() {
    return {
      isReal: this.character.character.o_type === 'real',
      isFiction: this.character.character.o_type === 'fiction',
      isNative: this.character.source_type === 'native',
      isMentioned: this.character.source_type === 'mentioned',
      showAdditionalSources: false,
    };
  },
  name: 'CharacterCardComponent',
  props: {
    character: {
      type: Object,
    },
  },
  methods: {
    detail_navigation(id, component) {
      specific_page_routing(this.$router, id, component)
    },
    additionalSources(e, id) {
      const div_id = 'additionalSources-' + id;
      this.showAdditionalSources = !this.showAdditionalSources;
      document.querySelector('#' + div_id).classList.toggle('hidden');
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
