<template>
  <router-view />
</template>

<script>
import 'slick-carousel/slick/slick.min.js';
export default {
  name: 'App',
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@layer base {
  body {
    font-family: 'Open Sans', sans-serif;
  }
}
HTML,
body {
  margin: 0px;
  padding: 0px;
  border: 0px;
}
</style>
