<template>
  <div v-if="value_prop" class="relative max-[767px]:px-5">
    <h2
      class="xl:text-xl lg:text-3xl md:text-xl sm:text-2xl max-[640px]:text-lg font-bold mt-14 mb-10"
    >
      {{ mediaSentence(key_prop) }}
    </h2>
    <div
      class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 sm:gap-4 max-[640px]:gap-y-4 mb-10"
    >
      <div v-for="(item, index) in local_prop_result" :key="index">
        <CardComponent :card_data="item" card_page="search_page" />
      </div>
    </div>

    <div v-if="show_more_result" class="main-item">
      <div class="animated-background">
        <div class="background-masker btn-divide-left"></div>
      </div>
    </div>
    <div
      v-if="value_prop.next != null && show_more_new_result"
      class="flex justify-center items-center cursor-pointer"
    >
      <SeeMoreIcon @click="show_more_results(page_refresh)" />
    </div>
  </div>
</template>

<script>
// import FavCardCompoent from './fav_card_component.vue';
import CardComponent from '@/common_components/card/card_component.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';
import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
  name: 'SearchedResults',
  components: {
    // FavCardCompoent,
    CardComponent,
    SeeMoreIcon,
  },
  computed: {
    ...mapState({
      show_result: (state) => state.home_search_store.show_result,
    }),
  },
  props: {
    key_prop: {
      type: String,
      required: true,
    },
    value_prop: {
      type: Object,
      required: true,
    },
    search_topic_display: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      show_more_result: false,
      show_more_new_result: true,
      local_prop_result: this.value_prop.results,
      page_refresh: 2,
    };
  },
  methods: {
    ...mapActions('home_search_store', ['show_more_results_api']),
    ...mapMutations('home_search_store', ['reset_home_search_result_list']),

    mediaSentence(key) {
    let temp_all_entity_type_values = JSON.parse(JSON.stringify(all_entity_type_values));

    if (key === 'media') {
      temp_all_entity_type_values['media'] = {
        entity_type_key: 'media',
        singular: 'Media',
        plural: 'Media',
      };
    }    
    return `${temp_all_entity_type_values[key]['singular']}: `;
  },


    async show_more_results(page) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.show_more_results_api({
            entity_type: this.key_prop,
            query: this.$route.params.search_topic,
            page: page,
          }),
        ]);
        this.local_prop_result = this.local_prop_result.concat(
          this.show_result[this.key_prop].results,
        );
        if (this.show_result[this.key_prop].next != null) {
          this.page_refresh = this.show_result[this.key_prop].next;
          this.show_more_new_result = true;
        } else {
          this.show_more_new_result = false;
        }
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },
  },
};
</script>

<style></style>
