<template>
  <div v-if="loading" class="loading-indicator">
    <div class="loader"></div>
  </div>
  <div v-else class="w-full mx-auto px-5 font-inter">
    <HomeHeaders />
    <div
      class="flex flex-col justify-center items-center py-[160px]"
      :style="{
        backgroundImage: `url(${require('../../images/media-search-bg.jpg')})`,
        backgroundSize: 'cover',
      }"
    >
      <h2 class="text-5xl text-white text-center font-bold pb-16">
        Quick Media Search
      </h2>
      <SearchComponent />
    </div>
    <!-- Quick Media Search End -->

    <!-- Recently View Section Start -->
    <section
      class="flex max-[767px]:flex-col mt-20 mb-10 max-[767px]:px-5 max-[767px]:mt-10"
    >
      <!-- Leftside Start -->
      <div class="leftside w-[30%] max-[767px]:w-full max-[767px]:mb-10">
        <!-- My Progress Start -->
        <div
          class="my-progress relative border-1 border-borderColor rounded-md pt-5"
        >
          <!-- <div
            class="absolute z-10 w-full text-center mt-10 bg-blur-lightblue py-5"
          >
            <h3 class="text-2xl font-semibold">Coming Soon!</h3>
            <p>Stay Tuned For Updates</p>
          </div> -->
          <div>
            <div class="px-5">
              <h2 class="text-[28px] font-bold text-center mb-5">
                My Progress
              </h2>
              <div class="flex justify-between items-center mb-2">
                <h3 class="text-2xl text-blue font-bold">Level {{badge_curoisity_data.level}}</h3>
                <p class="text-xs font-medium">{{badge_curoisity_data.xp}} XP</p>
              </div>
              <div class="relative bg-silver rounded-full h-6 w-full mb-7">
                <span
                  class="absolute left-0 top-0 bg-blue h-6 rounded-full"
                  :style="{ width: badge_curoisity_data.progress + '%' }"
                ></span>
              </div>
            </div>
            <div 
              v-if="badge_curoisity_data.badge.length > 0"
              class="grid xl:grid-cols-2 xl:gap-x-3 gap-y-4 h-96 overflow-y-auto px-3"
            >
              <BadgeCuroisityComponent 
                v-for="(badge_curoisity_data, index) in badge_curoisity_data.badge" 
                :key="index" 
                :curoisity_data="badge_curoisity_data"
              />
            </div>
            <div v-else class="grid xl:grid-cols-2 xl:gap-x-3 gap-y-4 h-96 overflow-y-auto px-3">
              No Badges Available
            </div>

          </div>
        </div>
        <!-- My Progress End -->
      </div>
      <!-- Leftside End -->

      <!-- Rightside Start -->
      <div class="px-10 max-[767px]:px-5 w-[70%] max-[767px]:w-full">
        <div class="flex justify-between">
          <h2
            class="text-[28px] max-[640px]:text-[18px] font-bold mb-8 max-[376px]:text-[14px] ml-11"
          >
            Recently Viewed
          </h2>
          <template
            v-if="
              recently_viewed_data &&
              recently_viewed_data.result.results.length > 3
            "
          >
            <a
              @click="search_result_navigate('recently_viewed')"
              class="flex items-center mb-8"
            >
              <span
                class="text-lg text-orange max-[376px]:text-sm cursor-pointer"
                >See more</span
              >
              <span class="ml-3">
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 1.16666L7.33333 6.99999L1.5 12.8333"
                    stroke="#FF9A00"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </a>
          </template>
        </div>
        <!-- Recently viewed slider start -->
        <template
          v-if="
            recently_viewed_data &&
            recently_viewed_data.result.results.length > 0
          "
        >
          <div class="relative">
            <div class="recently-viewed-slider swiper relative">
              <!-- 1st -->
              <div class="swiper-wrapper">
                <template v-for="(item, index) in recently_viewed_data.result.results" :key="index">
                  <CardComponent :card_data="item" :card_page="recent_view" />
                </template>
              </div>
            </div>
            <template v-if="recently_viewed_data.result.results.length > 3">
              <div class="previous-viewed">
                <button type="button" class="absolute z-20 top-[40%] -left-8">
                  <span class="icon-slider-leftarrow text-3xl"></span>
                </button>
              </div>
              <div class="next-viewed">
                <button type="button" class="absolute z-20 top-[40%] -right-8">
                  <span class="icon-slider-rightarrow text-3xl"></span>
                </button>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="w-full">
            <p>No Recently Viewed Media</p>
          </div>
        </template>
        <!-- Recently viewed slider end -->
        <div class="border-b-1 border-borderColor mt-14"></div>
      </div>
      <!-- Rightside Ed -->
    </section>
    <!-- Recently View Section End -->

    <!-- New All Favorites slider start   -->
    <section class="my-10 max-[767px]:px-5 max-[767px]:mt-0">
      <div class="flex justify-between items-center px-10 max-[767px]:px-5">
        <h2
          class="text-4xl font-bold mb-8 max-[767px]:text-3xl max-[440px]:text-2xl max-[376px]:text-xl"
        >
          All Favorites
        </h2>
        <template
          v-if="favorite_data && favorite_data.result.results.length > 2"
        >
          <a
            @click="search_result_navigate('favorite_media')"
            class="flex items-center mb-8 cursor-pointer"
          >
            <span class="text-lg text-orange max-[376px]:text-sm"
              >See more</span
            >
            <span class="ml-3">
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.16666L7.33333 6.99999L1.5 12.8333"
                  stroke="#FF9A00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </a>
        </template>
      </div>

      <!-- New All Favorites slider End -->
      <template v-if="favorite_data && favorite_data.result.results.length > 0">
        <div class="relative px-10 max-[767px]:px-5">
          <template v-if="favorite_data.result.results.length > 2">
            <div class="previous-newfavorites">
              <button type="button" class="absolute z-20 top-[40%] -left-0">
                <span class="icon-slider-leftarrow text-3xl"></span>
              </button>
            </div>
            <div class="next-newfavorites">
              <button type="button" class="absolute z-20 top-[40%] -right-0">
                <span class="icon-slider-rightarrow text-3xl"></span>
              </button>
            </div>
          </template>
          <div class="new-all-favorites-slider swiper w-full">
            <!-- component -->
            <div class="swiper-wrapper">
              <template v-for="(item, index) in favorite_data_list" :key="index">
                <FavCardComponent :favorite_list_data="item" />
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="relative px-10">No Favourites</div>
      </template>
      <!-- New All Favorites slider end -->
    </section>

    <!-- Trending Media Start -->
    <section class="mt-10 max-[767px]:px-5 max-[767px]:mt-0">
      <div class="flex justify-between items-center px-10 max-[767px]:px-5">
        <h2
          class="text-4xl font-bold mb-8 max-[767px]:text-3xl max-[440px]:text-2xl max-[376px]:text-xl"
        >
          Trending Media
        </h2>
        <template
          v-if="
            trending_media_data && trending_media_data.result.results.length > 3
          "
        >
          <a class="flex items-center mb-8">
            <span
              @click="search_result_navigate('trending_media')"
              class="text-lg text-orange max-[376px]:text-sm cursor-pointer"
              >See more</span
            >
            <span class="ml-3">
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.16666L7.33333 6.99999L1.5 12.8333"
                  stroke="#FF9A00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </a>
        </template>
      </div>

      <!-- Trending Media slider start -->
      <template
        v-if="
          trending_media_data && trending_media_data.result.results.length > 0
        "
      >
        <div class="relative px-10 max-[767px]:px-5">
          <template v-if="trending_media_data.result.results.length > 4">
            <div class="previous-trending-media">
              <button type="button" class="absolute z-20 top-[40%] -left-0">
                <span class="icon-slider-leftarrow text-3xl"></span>
              </button>
            </div>
            <div class="next-trending-media">
              <button type="button" class="absolute z-20 top-[40%] -right-0">
                <span class="icon-slider-rightarrow text-3xl"></span>
              </button>
            </div>
          </template>
          <div class="trending-media-slider swiper justify-center w-full">
            <div class="swiper-wrapper">
              <!-- 1st -->
              <template v-for="(item, index) in trending_media_data.result.results" :key="index">
                <CardComponent :card_data="item" />
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="w-full px-10">
          <p>No Trending Media</p>
        </div>
      </template>
      <!-- Trending Media slider end -->
    </section>
    <!-- Trending Media End -->

    <!-- Feature Media Start -->
    <section class="mt-10 max-[767px]:px-5 max-[767px]:mt-0">
      <div class="flex justify-between items-center px-10 max-[767px]:px-5">
        <h2
          class="text-4xl font-bold mb-8 max-[767px]:text-3xl max-[440px]:text-2xl max-[376px]:text-xl"
        >
          Featured Media
        </h2>
        <template
          v-if="
            feature_media_data && feature_media_data.result.results.length > 4
          "
        >
          <a class="flex items-center mb-8">
            <span
              @click="search_result_navigate('featured_media')"
              class="text-lg text-orange max-[376px]:text-sm cursor-pointer"
              >See more</span
            >
            <span class="ml-3">
              <svg
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.16666L7.33333 6.99999L1.5 12.8333"
                  stroke="#FF9A00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </a>
        </template>
      </div>

      <!-- Feature Media slider start -->
      <template
        v-if="
          feature_media_data && feature_media_data.result.results.length > 0
        "
      >
        <div class="relative px-10 max-[767px]:px-5">
          <template v-if="feature_media_data.result.results.length > 4">
            <div class="previous-feature-media">
              <button type="button" class="absolute z-20 top-[40%] -left-0">
                <span class="icon-slider-leftarrow text-3xl"></span>
              </button>
            </div>
            <div class="next-feature-media">
              <button type="button" class="absolute z-20 top-[40%] -right-0">
                <span class="icon-slider-rightarrow text-3xl"></span>
              </button>
            </div>
          </template>
          <div class="featured-media-slider swiper justify-center w-full">
            <div class="swiper-wrapper">
              <template v-for="(item, index) in feature_media_data.result.results" :key="index">
                <CardComponent :card_data="item" />
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="w-full pt-10 px-10">
          <p>No Featured Media</p>
        </div>
      </template>
      <!-- Feature Media slider end -->
    </section>
    <!-- Feature Media End -->

    <div
      class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"
    ></div>

    <HomeFooter />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { initializeSliders } from './home_slider.js';
import CardComponent from '@/common_components/card/card_component.vue';
import SearchComponent from '@/controller/home/components/search_component.vue';
import FavCardComponent from '@/common_components/fav_card/fav_card_component.vue';
import { all_media_type_values } from '@/common_variables/media_type.js';
import BadgeCuroisityComponent from './components/badge_curoisity_component.vue'

export default {
  name: 'HomeScreen',
  components: {
    HomeHeaders,
    HomeFooter,
    CardComponent,
    SearchComponent,
    FavCardComponent,
    BadgeCuroisityComponent
  },

  data() {
    return {
      loading: false,
      recent_view: 'recent_view',
      favorite_view: 'favorite_view',
      selected_favorite_data: null,
      show_more_result: false,
      page: 1,
      media_type_list: Object.keys(all_media_type_values).map(key => all_media_type_values[key].media_type_key),
    };
  },

  computed: {
    ...mapState({
      recently_viewed_data: (state) => state.home_store.recently_viewed_data,
      trending_media_data: (state) => state.home_store.trending_media_data,
      feature_media_data: (state) => state.home_store.feature_media_data,
      favorite_data: (state) => state.home_store.favorite_data,
      favorite_data_list: (state) => state.home_store.favorite_data_list,
      selected_tab: (state) => state.home_store.selected_tab,
      badge_curoisity_data: (state) => state.home_store.badge_curoisity_data
    }),
  },

  async created() {
    this.set_selected_tab('identify');
    await this.fetchDataFromApis();
    initializeSliders();
  },

  mounted() {},

  methods: {
    ...mapActions('home_store', [
      'recently_viewed_api',
      'trending_media_api',
      'feature_media_api',
      'profile_api',
      'all_favorite_api',
      'badge_curoisity_api'
    ]),
    ...mapMutations('home_store', ['set_selected_tab']),

    mediaSentence() {
      if (!this.selected_favorite_data) {
        return '';
      }
      if (
        this.media_type_list.includes(this.selected_favorite_data.entity_type)
      ) {
        return `Related media for this ${this.selected_favorite_data.fav_type} are as follows:`;
      } else {
        return `This ${this.selected_favorite_data.fav_type} appears in the following media:`;
      }
    },
    async show_more_results(page) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.all_favorite_api({
            page: page,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },

    select_favorite_data(data) {
      data;
    },

    search_result_navigate(search_type) {
      this.$router.push({
        name: 'search-result',
        query: {
          media: 'all',
          search_type: search_type,
          selected_media: 'All',
        },
      });
    },

    openAllMediaDropdown() {
      this.dropdownHidden = !this.dropdownHidden;
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.recently_viewed_api(),
          this.trending_media_api(),
          this.feature_media_api(),
          this.profile_api(),
          this.all_favorite_api({ page: this.page }),
          this.badge_curoisity_api()
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.selected_favorite_data = this.favorite_data.result.results[0];
        this.loading = false;
      }
    },
  },

};
</script>

<style>

@import '../../css/icomoon.css';
@import '../../css/style.css';
@import '../../css/discovery.css';

.recently-viewed-slider,
.all-favorites-slider,
.activity-media-slider,
.trending-media-slider,
.new-all-favorites-slider,
.featured-media-slider {
  overflow: hidden;
}

</style>
