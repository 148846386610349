import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const identify_store = {
  namespaced: true,
  state: {
    identify_media_data: null,
  },
  mutations: {
    set_identify_media_data(state, data) {
      state.identify_media_data = data;
    },
    set_identify_data_null(state) {
      state.identify_media_data = null;
    },
  },
  actions: {

    identify_media_data_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.identify_api.replace('{id}', payload.id)}`)
          .then((response) => {
            commit('set_identify_media_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default identify_store;
