<template>
  <div class="w-full px-10 max-[767px]:px-5 mx-auto">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
    <button
      class="px-10 py-4 max-[414px]:px-4 max-[414px]:py-1 text-blue text-lg font-medium border-1 border-blue rounded-full hover:text-white hover:bg-blue duration-500"
      @click="goBack"
    >
      Back
    </button>
    <div v-if="loading" class="loading-indicator">
      <div class="loader"></div>
    </div>
    <div v-else class="w-full mx-auto my-10 font-inter max-[654px]:px-5 px-10">
      <h2 class="text-[40px] max-[654px]:text-3xl font-bold text-center mb-10">
        Add Quote
      </h2>
      <h2
        class="text-4xl text-blue max-[767px]:text-3xl font-bold text-center mb-10"
      >
        {{ this.$route.params.media_name }}
      </h2>
      <form>
        <div class="w-full mb-9">
          <label
            for="quote"
            class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
            >Quote<span class="text-red-500">*</span></label
          >
          <textarea
            v-model="quote"
            id="quote"
            rows="5"
            cols="30"
            class="border-1 border-borderColor rounded-3xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          ></textarea>
        </div>
        <div class="w-full mb-7">
          <label
            for="said"
            class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
            >Who said it<span class="text-red-500">*</span></label
          >
          <input
            type="text"
            v-model="said"
            class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
            id="said"
            placeholder=""
          />
        </div>
        <div class="w-full mb-7">
          <label
            for="location"
            class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
            >Location in Media<span class="text-red-500">*</span></label
          >
          <input
            type="text"
            v-model="media_location"
            class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
            id="location"
            placeholder=""
          />
        </div>
        <div class="flex justify-center items-center">
          <p class="mr-4 text-lg font-medium text-gray-900 dark:text-gray-300">
            Submit to Curators
          </p>
          <label class="relative cursor-pointer">
            <input
              v-model="submit_curator"
              type="checkbox"
              class="sr-only peer"
            />
            <div
              class="w-11 h-6 flex items-center bg-gray-300 rounded-full peer peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"
            ></div>
          </label>
        </div>
        <p class="text-sm text-[#8f8f8f] text-center my-7">
          SUBMITTING spam, offensive content, or any other unwanted data to the
          curators for review may result in action taken against your account,
          including but not limited to temporary or permanent suspension of
          access.
        </p>
        <div class="flex justify-center">
          <button
            @click.prevent="submitForm"
            class="text-4xl max-[767px]:text-2xl text-white font-bold text-center bg-blue border-2 border-blue px-20 max-[767px]:px-10 py-3 max-[767px]:py-2 rounded-full hover:bg-white hover:border-blue hover:text-blue duration-500"
          >
            Create
          </button>
        </div>
      </form>
    </div>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { mapActions } from 'vuex';

export default {
  name: 'AddQuote',
  components: {
    HomeHeader,
    HomeFooter,
  },
  data() {
    return {
      loading: false,
      quote: null,
      said: null,
      media_location: null,
      submit_curator: false,
      errors: {
        quote: false,
        said: false,
        media_location: false,
      },
    };
  },
  methods: {
    ...mapActions('add_quote_store', ['post_add_quote_data_api']),
    ...mapActions('review_quote_store', ['get_quote_data_api']),
    validateForm() {
      this.errors.quote = !this.quote;
      this.errors.said = !this.said;
      this.errors.media_location = !this.media_location;

      if (this.errors.quote) {
        this.$toast.open({
          message: 'Quote cannot be empty',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }

      if (this.errors.said) {
        this.$toast.open({
          message: 'Who said it cannot be empty',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }

      if (this.errors.media_location) {
        this.$toast.open({
          message: 'Location in Media cannot be empty',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }

      return (
        !this.errors.quote && !this.errors.said && !this.errors.media_location
      );
    },
    async submitForm() {
      if (this.validateForm()) {
        const payload = {
          quote: this.quote,
          said_by: this.said,
          location: this.media_location,
          submit_to_curator: this.submit_curator,
          discovery_media: this.$route.params.id,
        };
        this.loading = true;
        await Promise.all([
          this.post_add_quote_data_api(payload)
            .then(() => {
              this.$toast.open({
                message: 'Quote is added successfully.',
                type: 'success',
                duration: 1000 * 5,
                dismissible: true,
              }),
                this.get_quote_data_api({ id: this.$route.params.id }),
                this.$router.go(-1);
            })
            .catch((error) => {
              this.$toast.open({
                message: 'Failed to add quote. Please try again.',
                type: 'error',
                duration: 1000 * 5,
                dismissible: true,
              });
              console.error('Failed to add quote:', error);
            }),
        ]);
        this.loading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
