<template>
  <div
    class="w-full bg-white shadow-gray-400 shadow-[rgba(0,0,0,0.2)_0px_0px_5px_0px]"
  >
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
  </div>

  <div class="w-full px-5 mx-auto my-14 font-inter max-[640px]:px-4 bg-white">
    <div class="flex justify-between">
      <button
        class="px-10 py-4 max-[414px]:px-4 max-[414px]:py-1 text-blue text-lg font-medium border-1 border-blue rounded-full hover:text-white hover:bg-blue duration-500"
        @click="goBack"
      >
        Back
      </button>
      <button
        @click="add_missing_info()"
        class="py-3 px-7 max-[414px]:px-4 max-[414px]:py-1 text-white bg-blue text-sm font-medium border-1 border-blue rounded-full hover:text-blue hover:bg-white duration-500"
      >
        Add Helpful Information
      </button>
    </div>

    <div class="flex-col justify-center items-center mt-5 mb-10">
      <h2
        class="text-3xl text-blue font-bold mb-2 max-[640px]:text-[24px] text-center"
      >
        {{ this.$route.params.media_name }}
      </h2>
      <p class="text-center text-2xl font-bold text-gray-700">
        {{ all_entity_type_display[entity_key]['plural'] }}
        <span class="text-blue text-xl">{{
          see_all_entity_result?.count
        }}</span>
      </p>
      <div
        class="flex justify-center mx-auto my-7 flex-row items-center relative bg-white rounded-full w-9/12 max-[1023px]:w-11/12"
      >
        <input
          v-model="search_query"
          type="text"
          placeholder="What do you want to find?"
          class="px-7 py-3 max-[640px]:px-3 focus:outline-none w-full border-1 border-borderColor rounded-3xl"
          @keyup.enter="search_result()"
        />
        <button
          @click="search_result()"
          class="flex items-center absolute right-0 bg-blue px-8 max-[1023px]:px-5 max-[360px]:px-3 border-1 border-blue py-2 top-0 rounded-full text-white"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28 28L20 20M22.6667 13.3333C22.6667 18.488 18.488 22.6667 13.3333 22.6667C8.17868 22.6667 4 18.488 4 13.3333C4 8.17868 8.17868 4 13.3333 4C18.488 4 22.6667 8.17868 22.6667 13.3333Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="ml-3 text-xl max-[640px]:hidden">Search</span>
        </button>
      </div>
    </div>

    <div
      v-if="group_loading === false && group_result.result.length > 0"
      class="relative flex items-center my-10 px-10 max-[767px]:px-5"
    >
      <template v-if="group_result.result.length > 4">
        <button
          class="absolute z-20 top-[40%] -left-0 left"
          @click="scrollLeft"
          aria-label="Scroll left"
        >
          <span class="icon-slider-leftarrow text-2xl"></span>
        </button>
        <button
          class="absolute z-20 top-[40%] -right-0 right"
          @click="scrollRight"
          aria-label="Scroll right"
        >
          <span class="icon-slider-rightarrow text-2xl"></span>
        </button>
      </template>
      <div
        class="items-center w-full mt-5 filter-slider-container"
        ref="filters"
        role="group"
        aria-label="Filter options"
      >
        <GroupComponent
          v-for="(item, index) in group_result?.result"
          :key="index"
          :label="item.name"
          :count="item.entity_count"
          :selected_category="selected_category"
          @select="select_category"
        />
      </div>
    </div>

    <section
      :class="entity_key === all_entity_type_display['purchase_oppurtunity' ]['entity_type_key'] 
      ? 'border-1 border-borderColor rounded-3xl w-full bg-[#fafafa] xl:p-10 p-5 mb-10 mt-9' 
      : 'border-1 border-borderColor rounded-3xl w-full px-5 pt-5 pb-10 mb-10'"
      
    >
      <template v-if="loading === false">
        <template v-if="see_all_entity_result_list.length > 0">
          <div
            v-if="entity_key !== all_entity_type_display['purchase_oppurtunity' ]['entity_type_key']"
            class="grid md:grid-cols-3 sm:grid-cols-2 sm:gap-4 max-[640px]:gap-y-4 mb-10 lg:grid-cols-2"
          >
          <component
            :is="all_entity_type_display[entity_key]['card_component']"
            v-for="(result, index) in see_all_entity_result_list"
            :key="index"
            v-bind="{ [all_entity_type_display[entity_key]['entity_type_key']]: result }"
          />
          </div>

          
          <div
            v-if="entity_key === 'purchase_oppurtunity'"
            class="grid md:grid-cols-3 sm:grid-cols-2 sm:gap-4 max-[640px]:gap-y-4 mb-10 lg:grid-cols-4"
          >
            <PurchaseCardActivityComponent
              v-for="(po, index) in see_all_entity_result_list"
              :key="index"
              :purchase_oppurtunity="po"
            />
          </div>
          <div v-if="show_more_result" class="main-item">
            <div class="animated-background">
              <div class="background-masker btn-divide-left"></div>
            </div>
          </div>
          <!-- More Slides Down Arrow -->
          <div
            v-if="see_all_entity_result.next !== null"
            class="flex justify-center items-center"
          >
            <SeeMoreIcon @click="see_more_result(see_all_entity_result.next)" />
          </div>
        </template>

        <template v-else> No data available </template>
      </template>
      <template v-else>
        <div class="loading-container">
          <div class="loader"></div>
        </div>
      </template>
    </section>
  </div>

  <!-- Footer Start -->
  <HomeFooter />
  <!-- Footer End -->
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import CharacterCardComponent from '@/controller/media_data/components/describe_components/character/character_card_component.vue';
import PlaceCardComponent from '@/controller/media_data/components/describe_components/place/place_card_component.vue';
import ThingCardComponent from '@/controller/media_data/components/describe_components/thing/thing_card_component.vue';
import TimePeriodCardComponent from '@/controller/media_data/components/describe_components/time_period/time_period_card_component.vue';
import GenreCardComponent from '@/controller/media_data/components/describe_components/genre/genre_card_component.vue';
import TopicCardComponent from '@/controller/media_data/components/describe_components/topic/topic_card_component.vue';
import ThemeCardComponent from '@/controller/media_data/components/describe_components/theme/theme_card_component.vue';
import ActivityCardComponent from '@/controller/media_data/components/enhance/activity/activity_card_component.vue';
import CareerInterestCardComponent from '@/controller/media_data/components/enhance/career_interest/career_interest_card_component.vue';
import PurchaseCardActivityComponent from '../media_data/components/enhance/purchase_opp/purchase_card_component.vue';
import GroupComponent from '@/controller/media_data/components/common_component/group_component.vue';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
  name: 'SeeMoreEntity',
  components: {
    HomeHeader,
    HomeFooter,
    CharacterCardComponent,
    PlaceCardComponent,
    ThingCardComponent,
    TimePeriodCardComponent,
    GenreCardComponent,
    TopicCardComponent,
    ThemeCardComponent,
    ActivityCardComponent,
    CareerInterestCardComponent,
    PurchaseCardActivityComponent,
    GroupComponent,
    SeeMoreIcon,
  },

  computed: {
    ...mapState({
      see_all_entity_result: (state) =>
        state.see_all_entity_store.see_all_entity_result,
      see_all_entity_result_list: (state) =>
        state.see_all_entity_store.see_all_entity_result_list,
      group_result: (state) => state.see_all_entity_store.group_result,
    }),
    all_entity_type_display(){
      return all_entity_type_values
    }
  },

  async created() {
    this.reset_see_all_entity_result();
    await Promise.all([
      this.fetchSeeAllEntityDataFromApis(
        this.$route.params.id,
        this.page,
        this.entity_key,
        this.search_query,
        this.selected_category,
      ),
      this.fetchGroupDataFromApis(this.$route.params.id, this.entity_key),
    ]);
    this.selected_category = this.group_result?.result[0]?.name;
  },

  data() {
    return {
      loading: true,
      entity_key: this.$route.params.type,
      page: 1,
      show_more_result: false,
      check_page_route: this.$route.query.page,
      selected_category: '',
      search_query: '',
    };
  },
  methods: {
    ...mapActions('see_all_entity_store', [
      'see_all_entity_result_api',
      'group_result_api',
    ]),
    ...mapMutations('see_all_entity_store', ['reset_see_all_entity_result']),
    ...mapMutations('home_store', ['set_selected_tab']),

    scrollLeft() {
      this.$refs.filters.scrollBy({
        top: 0,
        left: -180, // Adjust scroll distance as needed
        behavior: 'smooth',
      });
    },
    scrollRight() {
      this.$refs.filters.scrollBy({
        top: 0,
        left: 180, // Adjust scroll distance as needed
        behavior: 'smooth',
      });
    },
    add_missing_info() {
      this.$router.push({
        name: 'add-missing-info',
        params: { id: this.$route.params.id },
        query: {
          media_name: this.$route.params.media_name,
          media_type: this.$route.params.media_type,
          page: 'see_more_page',
        },
      });
    },
    async search_result() {
      if (!this.search_query) {
        this.$toast.open({
          message: 'Please enter a query.',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else {
        this.page = 1;
        this.reset_see_all_entity_result();
        await this.fetchSeeAllEntityDataFromApis(
          this.$route.params.id,
          this.page,
          this.entity_key,
          this.search_query,
          this.selected_category,
        );
      }
    },

    async select_category(category) {
      this.reset_see_all_entity_result();
      this.page = 1;
      this.search_query = '';
      this.selected_category = category;
      await this.fetchSeeAllEntityDataFromApis(
        this.$route.params.id,
        this.page,
        this.entity_key,
        this.search_query,
        this.selected_category,
      );
    },

    async see_more_result() {
      if (this.see_all_entity_result.next !== null) {
        this.page = this.see_all_entity_result.next;
        await this.show_more_results(
          this.$route.params.id,
          this.page,
          this.entity_key,
          this.search_query,
          this.selected_category,
        );
      }
    },

    async show_more_results(
      media_id,
      page,
      entity_key,
      search_query,
      selected_category,
    ) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.see_all_entity_result_api({
            id: media_id,
            page: page,
            entity_key: entity_key,
            search_query: search_query,
            selected_category:
              selected_category !== null && selected_category !== undefined
                ? encodeURIComponent(selected_category)
                : '',
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },

    async fetchSeeAllEntityDataFromApis(
      media_id,
      page,
      entity_key,
      search_query,
      selected_category,
    ) {
      this.loading = true;
      try {
        await Promise.all([
          this.see_all_entity_result_api({
            id: media_id,
            page: page,
            entity_key: entity_key,
            search_query: search_query,
            selected_category:
              selected_category !== null && selected_category !== undefined
                ? encodeURIComponent(selected_category)
                : '',
            check_page_route: this.check_page_route,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchGroupDataFromApis(media_id, entity_type) {
      this.group_loading = true;
      try {
        await Promise.all([
          this.group_result_api({
            id: media_id,
            entity_type: entity_type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.group_loading = false;
      }
    },

    goBack() {
      if (this.check_page_route !== undefined) {
        if (
          [
            this.all_entity_type_display['character']['entity_type_key'],
            this.all_entity_type_display['place']['entity_type_key'],
            this.all_entity_type_display['item']['entity_type_key'],
            this.all_entity_type_display['time_period']['entity_type_key'],
            this.all_entity_type_display['theme']['entity_type_key'],
            this.all_entity_type_display['genre']['entity_type_key'],
            this.all_entity_type_display['topic']['entity_type_key'],
          ].includes(this.entity_key)
        ) {
          this.set_selected_tab('describe');
        } else if (
          [
          this.all_entity_type_display['activity']['entity_type_key'],
          this.all_entity_type_display['purchase_oppurtunity']['entity_type_key'],
          this.all_entity_type_display['career_interest']['entity_type_key'],
          ].includes(
            this.entity_key,
          )
        ) {
          this.set_selected_tab('enhance');
        } else {
          this.set_selected_tab('identify');
        }
        media_page_navigation(
          this.$router, 
          this.$route.params.id, 
          this.$route.params.media_name,
          this.$route.params.media_type,
        );
      } else {
        this.$router.go(-1);
      }
    },
  },

  watch: {
    search_query(newValue) {
      console.log('search query is updating');
      if (newValue === '') {
        this.selected_category = '';
        this.page = 1;
        this.fetchSeeAllEntityDataFromApis(
          this.$route.params.id,
          this.page,
          this.entity_key,
          this.search_query,
        );
      }
    },
  },
};
</script>

<style></style>
