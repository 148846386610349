<template>
  <footer>
    <div class="flex justify-center items-center py-5 gap-12">
      <a href="#"><span class="icon-facebook text-2xl"></span></a>
      <a href="#"><span class="icon-instagram text-2xl"></span></a>
      <a href="#">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.533 7.82611C21.5482 8.03929 21.5482 8.25253 21.5482 8.46571C21.5482 14.9682 16.599 22.4606 7.5533 22.4606C4.76648 22.4606 2.17767 21.6535 0 20.2525C0.395953 20.2982 0.776625 20.3134 1.18781 20.3134C3.48727 20.3134 5.60405 19.5368 7.29441 18.2119C5.13197 18.1662 3.31978 16.75 2.69541 14.8007C3 14.8464 3.30455 14.8769 3.62437 14.8769C4.06598 14.8769 4.50764 14.8159 4.91878 14.7094C2.66498 14.2525 0.974578 12.2728 0.974578 9.88195V9.82106C1.62937 10.1865 2.39086 10.415 3.19791 10.4454C1.87303 9.56212 1.00505 8.05453 1.00505 6.34893C1.00505 5.43525 1.24866 4.59768 1.67508 3.86671C4.09641 6.85148 7.73602 8.80068 11.8172 9.01392C11.7411 8.64843 11.6954 8.26776 11.6954 7.88704C11.6954 5.17636 13.8883 2.96826 16.6141 2.96826C18.0304 2.96826 19.3095 3.56217 20.208 4.52156C21.3197 4.30837 22.3857 3.89718 23.3299 3.33375C22.9643 4.4759 22.1877 5.43529 21.1674 6.04439C22.1573 5.93784 23.1167 5.66367 23.9999 5.283C23.33 6.25757 22.4924 7.12556 21.533 7.82611Z"
            fill="#111827"
          />
        </svg>
      </a>
      <a href="#"><span class="icon-youtube text-2xl"></span></a>
    </div>
    <div
      class="flex justify-center max-[640px]:flex-col max-[640px]:items-center gap-5 text-lg font-bold"
    >
      <a href="https://www.in-formation.io/terms-of-use" target="_blank"
        >Terms of Use</a
      >
      <a href="https://www.in-formation.io/privacy-policy" target="_blank"
        >Privacy & Policy</a
      >
      <!-- <a href="">Press Room</a> -->
    </div>
    <div
      class="flex justify-center justify-self-center py-5 text-sm text-[#6B7280] font-bold"
    >
      © 2024 Discovery by In-Formation
    </div>
  </footer>
</template>

<script>
export default {
  name: 'HomeFooter',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
