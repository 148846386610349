<template>
  <div
    class="w-full bg-white shadow-gray-400 shadow-[rgba(0,0,0,0.2)_0px_0px_5px_0px]"
  >
    <HomeHeader />
  </div>
  <div v-if="loading" class="loading-indicator">
    <div class="loader"></div>
  </div>

  <div v-else class="w-full mx-auto my-10 font-inter max-[654px]:px-5 px-10">
    <button
      class="px-10 py-4 max-[414px]:px-4 max-[414px]:py-1 text-blue text-lg font-medium border-1 border-blue rounded-full hover:text-white hover:bg-blue duration-500"
      @click="goBack"
    >
      Back
    </button>
    <h2 class="text-2xl max-[654px]:text-3xl font-bold text-center">
      User Create Form
    </h2>
    <p class="text-base text-[#252525] text-center opacity-60 my-5">
      Any new data you add will only be visible to you until it is reviewed and
      approved by our curator team. This ensures the quality and accuracy of
      information on our platform.
    </p>
    <h2
      class="text-3xl max-[654px]:text-xl font-bold mb-10 text-center text-blue"
    >
      {{ media_name }}
    </h2>

    <form>
      <div class="w-full mb-10 relative">
        <span
          class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] text-[#ADB5BD] icon-updownarrow pointer-events-none"
        ></span>
        <label
          for="dataType"
          class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
          >Data Type<span class="text-red-500">*</span></label
        >
        <select
          @change="selected_media_type_func($event)"
          class="border-1 border-borderColor rounded-full text-[#53585c] text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2 appearance-none"
          id="dataType"
        >
          <option
            v-for="(value, key, index) in entity_list.result"
            :key="index"
            :value="key"
          >
            {{ value }}
          </option>
        </select>
      </div>
      <div class="w-full mb-9">
        <label
          for="name"
          class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
          >Name<span class="text-red-500">*</span></label
        >
        <input
          v-model="name"
          type="text"
          class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          id="name"
          placeholder=""
        />
      </div>

      <div v-if="selected_media_type === all_entity_values['activity']['entity_type_key']" class="w-full mb-9">
        <label
          for="participants"
          class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
          >Number of participants<span class="text-red-500">*</span></label
        >
        <input
          v-model="no_of_participants"
          type="number"
          class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          id="participants"
          placeholder=""
        />
      </div>

      

      <template v-if="selected_media_type === all_entity_values['place']['entity_type_key']">
        <div class="w-full mb-9">
          <label
            for="google_map_link"
            class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
            >Google Map Link<span class="text-red-500">*</span></label
          >
          <input
            v-model="google_map_link"
            type="text"
            class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
            id="google_map_link"
            placeholder=""
          />
        </div>
        <div class="w-full mb-9">
          <label
            for="google_embed_iframe"
            class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
            >Google Embed Iframe<span class="text-red-500">*</span></label
          >
          <input
            v-model="google_embed_iframe"
            type="text"
            class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
            id="google_embed_iframe"
            placeholder=""
          />
        </div>
      </template>

      <div v-if="!not_description.includes(selected_media_type)"
        class="w-full mb-9"
      >
        <label
          for="description"
          class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
          >Description<span class="text-red-500">*</span></label
        >
        <textarea
          v-model="description"
          id="description"
          rows="5"
          cols="30"
          class="border-1 border-borderColor rounded-3xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
        ></textarea>
      </div>

      <div v-if="selected_media_type === all_entity_values['media_credit']['entity_type_key']" class="w-full mb-9">
        <label
          for="summary_description"
          class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
          >Summary Description<span class="text-red-500">*</span></label
        >
        <textarea
          v-model="summary_description"
          id="summary_description"
          rows="5"
          cols="30"
          class="border-1 border-borderColor rounded-3xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
        ></textarea>
      </div>

      <!-- custom upload file -->
      <div class="flex items-center justify-center w-full h-64">
        <template v-if="!show_avatar">
          <label
            for="dropzone-file"
            class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span class="font-semibold">Click to upload Image</span>
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-400">
                PNG, JPG (MAX. 1 MB)
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              class="hidden"
              @change="handleAvatarUpload"
              accept=".png, .jpg, .jpeg"
            />
          </label>
        </template>

        <!-- Image Preview -->
        <template v-else>
          <div class="relative w-full h-full">
            <img
              :src="show_avatar"
              alt="Preview"
              class="w-full h-full object-cover border border-gray-300 rounded-lg"
              style="object-fit: contain; object-position: center"
            />
            <!-- Clear Image Button -->
            <span
              @click="deleteAvatar"
              class="absolute top-2 right-2 text-red-600 px-2 py-1 cursor-pointer"
            >
              Clear
            </span>
          </div>
        </template>
      </div>

      <div class="flex justify-between max-[654px]:flex-col mt-10">
        <template v-if="!not_o_source_type.includes(selected_media_type)">
          <div
            v-if="!not_o_type.includes(selected_media_type)"
            class="flex items-center max-[654px]:justify-between"
          >
            <p
              class="mr-4 text-lg font-medium text-gray-900 dark:text-gray-300"
            >
              Real / Fiction
            </p>
            <label class="relative cursor-pointer">
              <input
                v-model="real_fiction"
                type="checkbox"
                class="sr-only peer"
              />
              <div
                class="w-11 h-6 flex items-center bg-gray-300 rounded-full peer peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"
              ></div>
            </label>
          </div>
          <div
            class="flex items-center max-[654px]:justify-between max-[654px]:my-3"
          >
            <p
              class="mr-4 text-lg font-medium text-gray-900 dark:text-gray-300"
            >
              Native / Mentioned
            </p>
            <label class="relative cursor-pointer">
              <input
                v-model="native_mentioned"
                type="checkbox"
                class="sr-only peer"
              />
              <div
                class="w-11 h-6 flex items-center bg-gray-300 rounded-full peer peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"
              ></div>
            </label>
          </div>
        </template>
        <div class="flex items-center max-[654px]:justify-between">
          <p class="mr-4 text-lg font-medium text-gray-900 dark:text-gray-300">
            Submit to Curators
          </p>
          <label class="relative cursor-pointer">
            <input
              v-model="submit_curator"
              type="checkbox"
              class="sr-only peer"
            />
            <div
              class="w-11 h-6 flex items-center bg-gray-300 rounded-full peer peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"
            ></div>
          </label>
        </div>
      </div>
      <p class="text-sm text-[#8f8f8f] text-center my-7">
        Submitting spam, offensive content, or any other unwanted data to the
        curators for review may result in action taken against your account,
        including but not limited to temporary or permanent suspension of
        access.
      </p>

      <div  v-if="selected_media_type === all_entity_values['character']['entity_type_key']"
        class="flex flex-col border-1 border-borderColor rounded-3xl px-8 py-6 mb-10 max-[654px]:px-3 max-[654px]:py-3"
      >
        <h3 class="text-xl font-bold mb-3">Character Traits </h3>
        <div class="flex relative mb-5">
          <input
            v-model="searchTraits"
            type="text"
            class="border-1 border-borderColor rounded-full w-full text-base text-[#ADB5BD] pl-6 py-3"
            placeholder="Search Traits..."
          />
          <div class="absolute top-0 right-0"></div>
        </div>
        <div class="h-36 overflow-x-auto">
          <div
            v-for="(value, index) in filteredTraits"
            :key="index"
            class="px-5 py-2 mb-1 rounded-full hover:bg-badgeBG"
          >
            <label class="flex items-center">
              <input
                @click="selected_char_trait_func(value['id'])"
                type="checkbox"
                :id="'trait-' + index"
                class="w-5 h-5 mr-3"
              />
              <span>{{ value['label'] }}</span>
            </label>
          </div>
        </div>
      </div>

      <div v-if="selected_media_type === all_entity_values['media_credit']['entity_type_key']"
        class="flex flex-col border-1 border-borderColor rounded-3xl px-8 py-6 max-[654px]:px-3 max-[654px]:py-3 mb-10"
      >
        <h3 class="text-xl font-bold mb-3">Role</h3>
        <div class="flex relative mb-5">
          <input
            v-model="searchRoles"
            type="text"
            class="border-1 border-borderColor rounded-full w-full text-base text-[#ADB5BD] pl-6 py-3"
            placeholder="Search Role..."
          />
          <div class="absolute top-0 right-0"></div>
        </div>
        <div class="h-36 overflow-x-auto">
          <div
            v-for="(value, index) in filteredRoles"
            :key="index"
            class="px-5 py-2 mb-1 rounded-full hover:bg-badgeBG"
          >
            <label class="flex items-center">
              <input
                @change="selected_role_func(value)"
                type="radio"
                name="role"
                :value="value"
                class="w-5 h-5 mr-3"
                :checked="selected_role === value"
              />
              <span>{{ value }}</span>
            </label>
          </div>
        </div>
      </div>

      <div v-if="!not_link.includes(selected_media_type)" class="w-full mb-9">
        <label
          for="link"
          class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
          >URL Link</label
        >
        <input
          v-model="link"
          type="text"
          class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
          id="link"
          placeholder=""
        />
      </div>
      
      <div
        v-if="
          selected_media_type !== all_entity_values['activity']['entity_type_key'] &&
          selected_media_type !== all_entity_values['purchase_oppurtunity']['entity_type_key']
        "
        class="w-full mb-9"
      >
        <label
          for="additional_resources"
          class="text-2xl max-[654px]:text-lg font-bold max-[640px]:text-lg"
          >Additional Resources</label
        >
        <div
          v-for="(resource, index) in additionalResources"
          :key="index"
          class="relative flex items-center mt-2"
        >
          <input
            type="text"
            class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 max-[640px]:px-4 max-[640px]:py-2"
            :placeholder="'Resource ' + (index + 1)"
            v-model="additionalResources[index]"
          />
          <!-- Remove button -->
          <button
            type="button"
            @click="removeResource(index)"
            class="absolute right-2 text-red-500 text-2xl ml-3"
          >
            -
          </button>
        </div>
        <button
          type="button"
          @click="addResource"
          class="text-blue-500 text-xl mt-2 flex items-center"
        >
          <span class="mr-1">+</span> Add Resource
        </button>
      </div>
    </form>

    <div class="flex mt-10">
      <button
        @click.prevent="submit_form()"
        class="border-1 rounded-full border-blue bg-blue px-12 py-3 max-[376px]:px-7 max-[376px]:py-1 text-4xl max-[640px]:text-2xl text-white font-medium hover:border-1 hover:bg-white hover:border-blue hover:text-blue duration-500"
      >
        Submit
      </button>
    </div>
  </div>
  <!-- Footer Start -->
  <HomeFooter />
  <!-- Footer End -->
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
import { mapState, mapActions, mapMutations } from 'vuex';
import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
  name: 'AddMissingInfo',
  components: {
    HomeHeader,
    HomeFooter,
  },

  async created() {
    await this.fetchDataFromApis();
  },

  computed: {
    ...mapState({
      char_traits: (state) => state.add_missing_info_store.char_traits,
      entity_list: (state) => state.add_missing_info_store.entity_list,
      role: (state) => state.add_missing_info_store.role,
      filteredTraits() {
        if (this.searchTraits) {
          return this.char_traits.result.filter((trait) =>
            trait.label.toLowerCase().includes(this.searchTraits.toLowerCase()),
          );
        }
        return this.char_traits.result;
      },
      filteredRoles() {
        let rolesArray = Object.values(this.role.result);
        if (this.searchRoles) {
          return rolesArray.filter((role) =>
            role.toLowerCase().includes(this.searchRoles.toLowerCase()),
          );
        }
        return rolesArray;
      },
      
      all_entity_values(){
        return all_entity_type_values
      },
      // selected_media_type(){ 
      //   return all_entity_type_values['character']['entity_type_key']
      // },
      // selected_media(){ 
      //   return all_entity_type_values['character']['singular']
      // }
    }),
  },
  data() {
    return {
      loading: false,
      selected_char_trait: {},
      selected_role: null,
      searchTraits: '',
      searchRoles: '',
      name: '',
      google_embed_iframe: '',
      google_map_link: '',
      media_name: this.$route.query.media_name,
      media_type: this.$route.query.media_type,
      applicable_formats: this.$route.query.media_type,
      description: '',
      summary_description: '',
      link: '',
      no_of_participants: '',
      real_fiction: false,
      native_mentioned: false,
      submit_curator: false,
      show_avatar: null,
      selected_media_type: all_entity_type_values['character']['entity_type_key'],
      selected_media:  all_entity_type_values['character']['singular'],
      image: '',
      not_o_source_type: [
        'theme',
        'activity',
        'career_interest',
        'purchase_oppurtunity',
        'media_credit',
      ],
      not_o_type: ['time_period', 'genre'],
      not_link: ['theme', 'genre', 'time_period', 'activity', 'media_credit'],
      not_description: ['purchase_oppurtunity', 'media_credit'],
      additionalResources: [],
    };
  },
  methods: {
    ...mapActions('add_missing_info_store', [
      'get_char_traits_api',
      'get_role_api',
      'get_entity_list_api',
      'add_entity_form_api',
    ]),

    ...mapMutations('home_store', ['set_selected_tab']),

    // openAvatarUpload() {
    //   document.getElementById('avatarUpload').click();
    // },

    addResource() {
      this.additionalResources.push('');
    },
    removeResource(index) {
      this.additionalResources.splice(index, 1);
    },

    handleAvatarUpload(event) {
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.$toast.open({
          message: 'Profile picture must be less than 1 MB',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.show_avatar = reader.result;
        this.image = reader.result;
      };
      reader.readAsDataURL(file);
    },

    deleteAvatar() {
      this.image = '';
      this.show_avatar = null;
    },

    isValidURL(url) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-zA-Z0-9\\-\\_\\.]+)\\.([a-zA-Z]{2,}))|' + // domain name
          'localhost|' + // localhost
          '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IPv4
          '\\[([a-fA-F0-9:]+)\\])' + // IPv6
          '(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // port and path
          '(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?' + // query string
          '(\\#[-a-zA-Z0-9_]*)?$',
        'i',
      ); // fragment locator
      return pattern.test(url);
    },

    validateInputs() {
      const errors = [];
      if (!this.name.trim()) {
        errors.push('Name cannot be empty.');
      }
      if (
        !this.description.trim() &&
        !this.not_description.includes(this.selected_media_type)
      ) {
        errors.push('Description cannot be empty.');
      }
      if (
        !this.summary_description.trim() &&
        this.selected_media_type === all_entity_type_values['media_credit']['entity_type_key']
      ) {
        errors.push('Summary Description cannot be empty.');
      }
      if (
        this.no_of_participants === '' &&
        this.selected_media_type === all_entity_type_values['activity']['entity_type_key']
      ) {
        errors.push('Number of participants cannot be empty.');
      } else if (parseInt(this.no_of_participants, 10) < 0) {
        errors.push('Number of participants cannot be negative.');
      }

      if (
        this.link.trim() &&
        (!this.isValidURL(this.link) &&
        !this.not_link.includes(this.selected_media_type))
      ) {
        errors.push('Link must be a valid URL.');
      }
      if (
        Object.keys(this.selected_char_trait).length === 0 &&
        this.selected_media_type === all_entity_type_values['character']['entity_type_key']
      ) {
        errors.push('Atleast select one Character Trait');
      }
      if (
        this.selected_media_type === all_entity_type_values['media_credit']['entity_type_key'] &&
        this.selected_role === null
      ) {
        errors.push('Select Role');
      }
      if (
        !this.google_map_link.trim() &&
        this.selected_media_type === all_entity_type_values['place']['entity_type_key']
      ) {
        errors.push('Google map link cannot be empty.');
      } else if (
        this.selected_media_type === all_entity_type_values['place']['entity_type_key'] &&
        !this.isValidURL(this.google_map_link)
      ) {
        errors.push('Google map link must be a valid URL.');
      }
      if (
        !this.google_embed_iframe.trim() &&
        this.selected_media_type === all_entity_type_values['place']['entity_type_key']
      ) {
        errors.push('Google embed frame cannot be empty.');
      } else if (
        this.selected_media_type === all_entity_type_values['place']['entity_type_key'] &&
        !this.isValidURL(this.google_embed_iframe)
      ) {
        errors.push('Google embed frame link must be a valid URL.');
      }

      if (
        this.additionalResources.length > 0 &&
        this.additionalResources[0].trim()
      ) {
        for (const url of this.additionalResources) {
          if (!this.isValidURL(url)) {
            errors.push('Addition Resource link must be a valid URL.');
            break;
          }
        }
      }

      return errors;
    },

    selected_media_type_func(event) {
      this.selected_media = event.target.selectedOptions[0].text;
      this.selected_media_type = event.target.value;
    },

    selected_char_trait_func(char_id) {
      if (this.selected_char_trait[char_id]) {
        delete this.selected_char_trait[char_id];
      } else {
        this.selected_char_trait[char_id] = true;
      }
    },

    selected_role_func(role) {
      console.log(role);
      const role_dictionary =
        this.$store.state.add_missing_info_store.role.result;
      for (let key in role_dictionary) {
        if (role_dictionary[key] === role) {
          this.selected_role = key;
          break;
        }
      }
    },

    navigate_see_all_entity() {
      this.$router.replace({
        name: 'see_more_entity',
        params: {
          id: this.$route.params.id,
          type: this.selected_media_type,
          media_name: this.media_name,
          media_type: this.media_type,
        },
        query: {
          page: this.$route.query.page,
        },
      });
    },

    navigate_media_page() {
      this.set_selected_tab('identify');
      media_page_navigation(
        this.$router, 
        this.$route.params.id,
        this.media_name,
        this.media_type
      );
    },

    async submit_form() {
      const errors = this.validateInputs();
      if (errors.length > 0) {
        this.$toast.open({
          message: errors.join(' '),
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      console.log('media_data_type: ', this.selected_media_type);
      let traits = [];
      traits.push(...Object.keys(this.selected_char_trait));

      let from_data_body = {
        media_id: this.$route.params.id,
        entity: this.selected_media_type,
        entity_data: {
          name: this.name,
          submit_to_curator: this.submit_curator,
          additional_resource: this.additionalResources,
        },
      };

      if (this.selected_media_type === all_entity_type_values['activity']['entity_type_key']) {
        from_data_body['entity_data']['no_of_participants'] =
          this.no_of_participants.toString();
      }

      if (
        this.selected_media_type === all_entity_type_values['activity']['entity_type_key'] ||
        this.selected_media_type === all_entity_type_values['purchase_oppurtunity']['entity_type_key']
      ) {
        delete from_data_body['entity_data']['additional_resource'];
      }

      if (this.selected_media_type === all_entity_type_values['media_credit']['entity_type_key']) {
        from_data_body['entity_data']['summary'] = this.summary_description;
        from_data_body['entity_data']['role'] = this.selected_role;
      }

      if (this.selected_media_type === all_entity_type_values['genre']['entity_type_key']) {
        from_data_body['entity_data']['applicable_formats'] = [
          this.applicable_formats,
        ];
      }

      if (this.selected_media_type === all_entity_type_values['character']['entity_type_key']) {
        from_data_body['entity_data']['traits'] = traits;
      }

      if (this.selected_media_type === all_entity_type_values['place']['entity_type_key']) {
        from_data_body['entity_data']['google_map_link'] = this.google_map_link;
        from_data_body['entity_data']['google_embed_iframe'] =
          this.google_embed_iframe;
      }

      if (!this.not_description.includes(this.selected_media_type)) {
        from_data_body['entity_data']['description'] = this.description;
      }

      if (this.link.trim() && !this.not_link.includes(this.selected_media_type)) {
        from_data_body['entity_data']['link'] = this.link;
      }

      if (!this.not_o_source_type.includes(this.selected_media_type)) {
        if (!this.not_o_type.includes(this.selected_media_type)) {
          from_data_body['entity_data']['o_type'] = this.real_fiction
            ? 'fiction'
            : 'real';
        }
        from_data_body['entity_data']['source_type'] = this.native_mentioned
          ? 'mentioned'
          : 'native';
      }

      if (this.image !== '') {
        from_data_body['entity_data']['image'] = this.image;
      }
      
      this.loading = true;
      await Promise.all([
        this.add_entity_form_api(from_data_body)
          .then(() => {
            this.$toast.open({
              message: 'Form Submitted',
              type: 'success',
              duration: 1000 * 5,
              dismissible: true,
            });
            if (this.selected_media_type === all_entity_type_values['media_credit']['entity_type_key']) {
              this.navigate_media_page();
            } else {
              this.navigate_see_all_entity();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              type: 'error',
              duration: 1000 * 5,
              dismissible: true,
            });
          }),
      ]);
      this.loading = false;
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.get_char_traits_api(),
          this.get_role_api(),
          this.get_entity_list_api(),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
