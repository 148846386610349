<template>
  <div
    @click="
      media_data_navigation(
        favorite_list_data.entity_id,
        favorite_list_data.entity_type,
        favorite_list_data.name,
        favorite_list_data.fav_type,
      )
    "
    class="shadow-[rgba(0,0,5,0.1)_0px_1px_5px_2px] rounded-3xl cursor-pointer swiper-slide md:h-[218px]"
  >
    <div class="flex max-[1280px]:flex-col items-center">
      <img
        :src="favorite_list_data.image"
        :alt="favorite_list_data.name"
        class="max-w-[218px] max-h-[218px] rounded-xl max-[767px]:mt-3"
      />
      <div class="flex flex-col justify-center px-6 py-3 w-full">
        <div class="flex justify-between items-center">
          <span class="text-xs text-[#9CA3AF]">{{
            favorite_list_data.year_pub || ''
          }}</span>
          <span
            class="bg-blue px-5 py-1 text-white text-base text-center rounded-full"
            >{{ favorite_list_data.fav_type }}</span
          >
        </div>
        <p
          class="text-3xl max-[768px]:text-2xl font-bold mt-3 md:truncate ... md:w-[380px]"
        >
          {{ favorite_list_data.name }}
        </p>
        <div class="flex py-3">
          <!-- <span class="text-xs">82.0 / 100</span> -->
          <div class="flex items-center">
            <MediaLikeComponent
              :identify_media_data="{
                id: favorite_list_data.entity_id,
                entity: favorite_list_data.entity_type,
                name: favorite_list_data.name,
                like: true,
                is_fav: true,
              }"
            />
            <span class="text-xl mx-3">Like</span>
            <!-- <span class="text-xs ml-3">Like</span>
              <span class="icon-redheart text-xs"></span> -->
          </div>
        </div>
        <p class="text-sm text-[#9CA3AF] md:truncate ... md:w-[380px]">
          {{ favorite_list_data.genre_or_media }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { media_page_navigation } from '../common_routing/media_page_routing';
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '../common_routing/specific_data_routing';
import { all_entity_type_values } from '@/common_variables/entity_type';
export default {
  name: 'FavCardComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    favorite_list_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    media_data_navigation(id, type, name) {
      if (Object.keys(all_entity_type_values).includes(type)) {
        specific_page_routing(this.$router, id, type)
      } else {
        media_page_navigation(this.$router, id, name, type);
      }
    },
  },
};
</script>

<style></style>
