<template>
    <div class="flex flex-col justify-between items-center border-1 border-borderColor px-3">
        <div class="flex flex-col items-center">
            <img :src="curoisity_data.image" alt="Badge Image" class="py-4"
                :class="{ 'filter grayscale': !curoisity_data.is_active }" />
            <p class="text-lg font-bold mb-6 text-center" :class="{
                'text-blue': curoisity_data.is_active,
                'text-inactiveBadgeTitle': !curoisity_data.is_active
            }">
                {{ curoisity_data.name }}
            </p>
        </div>
        <p class="text-xs text-silver font-inter font-normal mb-9">
            {{ curoisity_data.description ? curoisity_data.description : '' }}
        </p>
    </div>
</template>

<script>

export default {
    name: 'BadgeCuroisityComponent',
    props: {
        curoisity_data: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style></style>