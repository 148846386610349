<template>
  <a
    @click.prevent="handleClick"
    :class="[
      'text-xl border-1 rounded-full flex items-center justify-center w-full h-full whitespace-nowrap cursor-pointer',
      isSelected ? 'text-white bg-blue' : 'text-blue border-blue',
    ]"
    style="padding: 0.5rem 1rem; margin-right: 1.75rem"
  >
    {{ formattedLabel }}
  </a>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    selected_category: {
      type: String,
      default: null,
    },
  },
  computed: {
    isSelected() {
      return this.selected_category === this.label;
    },
    formattedLabel() {
      return `${this.label} (${this.count})`;
    },
  },
  methods: {
    handleClick() {
      this.$emit('select', this.label);
    },
  },
};
</script>

<style scoped>
.bg-blue {
  background-color: #242fb3;
}

.text-blue {
  color: #242fb3;
}

.border-blue {
  border-color: #242fb3;
}
</style>
