<template>
  <div class="w-full px-5 mx-auto">
    <HomeHeaders />
    <LoginPopup v-if="!isLogin" page="satisfy_curiosity" />
    <LoginPopup v-if="show_login_popup" page="satisfy_curiosity" />
    <div class="relative mx-auto font-inter">
      <!-- <div
        class="fixed z-50 w-[982px] max-[1024px]:w-full text-center mt-10 bg-white py-5"
      >
        <h3 class="text-2xl font-semibold">Coming Soon!</h3>
        <p>Stay Tuned For Updates</p>
      </div> -->
      <!-- <div class="blur-div"> -->
      <div class="max-[640px]:px-5">
        <div
          class="flex items-center max-[640px]:flex-col bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5"
        >
          <img
            src="../../images/bulb.png"
            alt="Bulb"
            class="max-[640px]:mb-3"
          />
          <p
            class="text-2xl font-bold ml-14 max-[640px]:ml-7 max-[640px]:text-lg"
          >
            Satisfy My Curiosity helps you find media suggestions based on your
            interests!
          </p>
        </div>
        <form class="mb-14">
          <div class="w-full mb-9">
            <label for="interest" class="text-2xl font-bold max-[640px]:text-lg"
              >To start, please enter a topic that you are interested in.</label
            >
            <input
              v-model="search_topic_query"
              type="text"
              class="border-1 border-borderColor rounded-full text-3xl max-[640px]:text-lg font-light w-full px-8 py-5 mt-7 max-[640px]:px-5 max-[640px]:py-3"
              placeholder="Search"
            />
          </div>
          <!-- <div class="w-full mb-9">
            <label
              for="interest"
              class="text-2xl font-bold max-[640px]:text-lg"
            >
              <p>Next, please enter why you are interested in this topic.</p>
              <p class="text-sm text-black opacity-40 font-medium mt-2">
                This data is exclusively forwarded to our media curation team,
                with the sole purpose of enhancing the quality of our
                recommendations.
              </p>
            </label>
            <textarea
              v-model="search_interest_query"
              name=""
              id=""
              cols="30"
              rows="10"
              class="border-1 border-borderColor rounded-3xl w-full px-8 py-5 mt-7 max-[640px]:px-5 max-[640px]:py-3"
            ></textarea>
          </div> -->
          <div class="flex">
            <button
              @click.prevent="search_navigation"
              class="border-1 rounded-full border-blue bg-blue px-12 py-3 max-[376px]:px-7 max-[376px]:py-1 text-4xl max-[640px]:text-2xl text-white font-medium hover:border-1 hover:bg-white hover:border-blue hover:text-blue duration-500"
            >
              Next
            </button>
          </div>
        </form>
      </div>
      <div
        class="recently-bottom-border border-b-1 border-borderColor mt-10 mb-7"
      ></div>
      <HomeFooter />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';
import { logout_messages } from '@/common_variables/logout_messages';

export default {
  name: 'SatisfyCuriosity',
  components: {
    HomeHeaders,
    HomeFooter,
    LoginPopup,
  },

  computed: {
    ...mapState({
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
    }),
  },

  data() {
    return {
      search_topic_query: '',
      search_interest_query: '',
      isLogin: false,
    };
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
      this.set_msg(logout_messages.satisfy_curiosity);
    } else {
      this.isLogin = true;
    }
  },
  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    search_navigation() {
      if (
        localStorage.getItem('token') === undefined ||
        localStorage.getItem('token') === null ||
        localStorage.getItem('token') === ''
      ) {
        this.set_msg(logout_messages.satisfy_curiosity);
        this.set_show_login_popup(true);
      } else if (this.search_topic_query.length == 0) {
        this.$toast.open({
          message: 'Enter a Search Topic',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else {
        if (this.search_interest_query == '') {
          this.$router.push({
            name: 'search-satisfy',
            params: {
              search_topic: this.search_topic_query,
              search_interest: 'null',
            },
          });
        } else {
          this.$router.push({
            name: 'search-satisfy',
            params: {
              search_topic: this.search_topic_query,
              search_interest: this.search_interest_query,
            },
          });
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
