<template>
  <span
    class="cursor-pointer"
    @click.stop="set_like_dislike()"
    :class="
      like_response
        ? `icon-redheart ${is_fav ? 'text-xl' : 'text-3xl'}`
        : `icon-redheart ${is_fav ? 'text-xl' : 'text-3xl'} grayscale`
    "
  ></span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { logout_messages } from '@/common_variables/logout_messages';
import { all_media_type_values } from '@/common_variables/media_type';
export default {
  name: 'MediaLikeComponent',
  props: {
    identify_media_data: Object,
  },

  data() {
    return {
      like_response: null,
      change_value: null,
      is_fav: false,
      media_type_list: Object.keys(all_media_type_values).map(key => all_media_type_values[key].media_type_key),
    };
  },

  mounted() {
    this.like_response = this.identify_media_data.like;
    this.change_value = this.identify_media_data.like;
    this.is_fav = this.identify_media_data.is_fav ? true : false;
  },

  methods: {
    ...mapActions('fav_card_icon_store', ['favourite_data_api']),
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    set_like_dislike() {
      if (
        localStorage.getItem('token') === undefined ||
        localStorage.getItem('token') === null ||
        localStorage.getItem('token') === ''
      ) {
        this.set_show_login_popup(true);
        this.set_msg(logout_messages.fav_message);
      } else {
        let entity_val = this.identify_media_data.entity;
        if (
          this.media_type_list.includes(entity_val)
        ) {
          entity_val = 'media';
        }
        this.like_response = !this.change_value;
        this.favourite_data_api({
          id: this.identify_media_data.id,
          like: this.like_response,
          entity: entity_val,
        });
        this.change_value = !this.change_value;
        this.$toast.open({
          message:
            (this.identify_media_data.name || this.identify_media_data.title) +
            (this.like_response
              ? ' added to favorite'
              : ' removed from favorite'),
          type: 'info',
          duration: 1000 * 5,
          dismissible: true,
        });
      }
    },
  },
};
</script>

<style></style>
