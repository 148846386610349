import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const home_logout_store = {
  namespaced: true,
  state: {
    most_viewed_data: null,
    trending_media_data: null,
    feature_media_data: null,
    show_login_popup: false,
    msg_to_show: '',
  },
  mutations: {
    set_most_viewed_data(state, data) {
      state.most_viewed_data = data;
    },
    set_trending_media_data(state, data) {
      state.trending_media_data = data;
    },
    set_feature_media_data_data(state, data) {
      state.feature_media_data = data;
    },
    set_show_login_popup(state, data) {
      state.show_login_popup = data;
    },
    set_msg(state, data) {
      state.msg_to_show = data;
    },
  },
  actions: {
    most_viewed_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.most_viewed_api)
          .then((response) => {
            commit('set_most_viewed_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    trending_media_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.trending_media_api)
          .then((response) => {
            commit('set_trending_media_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting another API:', error);
            reject(error);
          });
      });
    },

    feature_media_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.feature_media_api)
          .then((response) => {
            commit('set_feature_media_data_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },
  },
};

export default home_logout_store;
