<template src="../../screens/login_account/login_view.html"></template>

<script>
export default {
  name: 'LoginAccount',
  data() {
    return {
      email: '',
      password: '',
      passwordFieldType: 'password',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    create_account_navigate() {
      this.$router.push({ name: 'create-account' });
    },
    login_user() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        this.$toast.open({
          message: 'Invalid Email format!. Try Again',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else if (this.password.length < 8) {
        this.$toast.open({
          message: 'Invalid Password!. Try Again',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else {
        this.$store
          .dispatch('login_account_store/login_account_api', {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.$toast.open({
              message: 'Successfully logged in!',
              type: 'success',
              duration: 1000 * 5,
              dismissible: true,
            });
            this.$router.push({ name: 'home' });
          })
          .catch((error) => {
            console.log(error);
            this.$toast.open({
              message: error,
              type: 'error',
              duration: 1000 * 5,
              dismissible: true,
            });
          });
      }
    },
  },
};
</script>

<style src="../../css/style.css" scoped></style>
