// slider.js

import $ from 'jquery';

export default function initializeSliders() {
  $('.most-viewed-slider').slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.previous-viewed').click(function () {
    $('.most-viewed-slider').slick('slickPrev');
  });

  $('.next-viewed').click(function () {
    $('.most-viewed-slider').slick('slickNext');
  });

  $('.trending-media-slider').slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.previous-trending-media').click(function () {
    $('.trending-media-slider').slick('slickPrev');
  });

  $('.next-trending-media').click(function () {
    $('.trending-media-slider').slick('slickNext');
  });

  $('.featured-media-slider').slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.previous-feature-media').click(function () {
    $('.featured-media-slider').slick('slickPrev');
  });

  $('.next-feature-media').click(function () {
    $('.featured-media-slider').slick('slickNext');
  });
}
