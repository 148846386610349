<template>
  <div
    class="w-full bg-white shadow-gray-400 shadow-[rgba(0,0,0,0.2)_0px_0px_5px_0px]"
  >
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
  </div>
  <LoginPopup v-if="show_login_popup" />
  <div class="w-full px-5 mx-auto my-14 font-inter max-[640px]:px-4 bg-white">
    <div class="flex justify-between">
      <button
        class="px-10 py-4 max-[414px]:px-4 max-[414px]:py-1 text-blue text-lg font-medium border-1 border-blue rounded-full hover:text-white hover:bg-blue duration-500"
        @click="goBack"
      >
        Back
      </button>
      <button
        v-if="isLogin"
        @click="add_missing_info()"
        class="py-3 px-7 max-[414px]:px-4 max-[414px]:py-1 text-white bg-blue text-sm font-medium border-1 border-blue rounded-full hover:text-blue hover:bg-white duration-500"
      >
        Add Helpful Information
      </button>
    </div>
    <h2
      class="text-[28px] font-bold mb-10 max-[640px]:text-[20px] text-center text-blue"
    >
      {{ this.$route.query.media_name }}
    </h2>
    <!-- Tabs Start -->
    <div
      class="flex max-[767px]:flex-col max-[767px]:items-center max-[767px]:gap-y-5 py-5"
    >
      <button
        id="identify"
        @click="openTab('identify')"
        :class="
          selected_tab === 'identify'
            ? tab_class['underline_tab']
            : tab_class['without_underline_tab']
        "
      >
        Identify
      </button>
      <button
        id="experience"
        @click="openTab('experience')"
        :class="
          selected_tab === 'experience'
            ? tab_class['underline_tab']
            : tab_class['without_underline_tab']
        "
      >
        Experience
      </button>
      <button
        id="describe"
        @click="openTab('describe')"
        :class="
          selected_tab === 'describe'
            ? tab_class['underline_tab']
            : tab_class['without_underline_tab']
        "
      >
        Describe
      </button>
      <button
        id="enhance"
        @click="openTab('enhance')"
        :class="
          selected_tab === 'enhance'
            ? tab_class['underline_tab']
            : tab_class['without_underline_tab']
        "
      >
        Enhance
      </button>
      <button
        id="engage"
        @click="openTab('engage')"
        :class="
          selected_tab === 'engage'
            ? tab_class['underline_tab']
            : tab_class['without_underline_tab']
        "
      >
        Engage
      </button>
    </div>
    <!-- Tabs End -->

    <template v-if="selected_tab === 'experience' && isLogin">
      <ExperienceComponent />
    </template>

    <!-- Identify Section Start -->
    <template v-if="selected_tab === 'identify'">
      <IdentifyComponent />
    </template>
    <!-- Identify Section End -->

    <!-- Describe Section Start -->
    <template v-if="selected_tab === 'describe' && isLogin">
      <DescribeComponent />
    </template>
    <!-- Describe Section End -->

    <!-- Enhance Section Start -->
    <template v-if="selected_tab === 'enhance' && isLogin">
      <EnhanceComponent />
    </template>
    <!-- Enhance Section Start -->

    <!-- Enhance Section Start -->
    <template v-if="selected_tab === 'engage' && isLogin">
      <EngageComponent />
    </template>
    <!-- Enhance Section Start -->
  </div>

  <!-- Footer Start -->
  <HomeFooter />
  <!-- Footer End -->
</template>

<script>
// Import jQuery and Slick slider
import { mapState, mapMutations } from 'vuex';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import ExperienceComponent from './components/experience_component/experience_component.vue';
import IdentifyComponent from './components/identify_component/identify_component.vue';
import DescribeComponent from './components/describe_components/describe_component.vue';
import EnhanceComponent from './components/enhance/enhance_component.vue';
import EngageComponent from './components/engage_component/engage_component.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';
import { logout_messages } from '@/common_variables/logout_messages'

export default {
  name: 'MediaData',
  components: {
    HomeHeader,
    HomeFooter,
    ExperienceComponent,
    IdentifyComponent,
    DescribeComponent,
    EnhanceComponent,
    EngageComponent,
    LoginPopup,
  },

  computed: {
    ...mapState({
      identify_media_data: (state) =>
        state.media_data_store.identify_media_data,
      enhance_media_data: (state) => state.media_data_store.enhance_media_data,
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
      selected_tab: (state) => state.home_store.selected_tab,
    }),
  },

  data() {
    return {
      initialized: false,
      isLogin: false,
      show_tabs: null,
      tab_class: {
        underline_tab:
          'tab-button text-2xl text-blue border-b-2 pb-1 border-blue max-[767px]:ml-0 ml-7',
        without_underline_tab:
          'tab-button text-2xl text-black/40 max-[767px]:ml-0 ml-7',
      },
    };
  },

  async created() {
    this.initialized = true;
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.set_selected_tab('identify');
      this.isLogin = false;
      this.set_show_login_popup(true);
      this.set_msg(logout_messages.media_page);
    } else {
      this.isLogin = true;
    }
  },

  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),
    ...mapMutations('home_store', ['set_selected_tab']),

    openTab(tab) {
      if (this.isLogin) {
        this.set_selected_tab(tab);
      } else {
        this.show_tabs = false;
        this.set_show_login_popup(true);
        this.set_msg(logout_messages.media_page);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    add_missing_info() {
      this.$router.push({
        name: 'add-missing-info',
        params: { id: this.$route.params.id },
        query: {
          media_name: this.$route.query.media_name,
          media_type: this.$route.query.media_type,
          page: 'see_more_page',
        },
      });
    },
  },
};
</script>
