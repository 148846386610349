import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const specific_data_store = {
  namespaced: true,
  state: {
    entity_detail_result: null,
    related_media_result: null,
    related_media_result_list: [],
  },
  mutations: {
    set_character_entity_detail(state, data) {
      state.entity_detail_result = data;
    },
    set_character_related_media(state, data) {
      state.related_media_result = data;
    },
    set_character_related_media_list(state, data) {
      state.related_media_result_list =
        state.related_media_result_list.concat(data);
    },
    reset_character_related_media_list(state) {
      state.related_media_result_list = [];
    },
  },
  actions: {
    character_entity_detail_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.entity_detail_api + payload.id + '/' + payload.type)
          .then((response) => {
            commit('set_character_entity_detail', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    character_related_media_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            APIs.entity_related_media_api +
              payload.id +
              '/' +
              payload.type +
              '?page=' +
              String(payload.page),
          )
          .then((response) => {
            commit('set_character_related_media', response.data);
            commit(
              'set_character_related_media_list',
              response.data.result.results,
            );
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },
  },
};

export default specific_data_store;
