<template>
  <div
    @click="detail_navigation(place.place.id, place.place.entity)"
    class="shadow-md border-1 border-borderColor py-10 px-5 rounded-3xl cursor-pointer"
  >
    <div class="flex sm:max-lg:flex-col max-[640px]:flex-col">
      <div
        class="flex flex-col lg:w-[35%] max-[640px]:w-full sm:max-lg:items-center max-[640px]:items-center"
      >
        <div
          class="relative xl:h-[260px] lg:h-[161px] max-[1023px]:h-[285px] max-[1023px]:w-[210px] border-1 border-borderColor rounded-xl"
        >
          <img
            :src="place.place.image"
            :alt="place.place.name"
            class="rounded-xl absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto p-2"
          />
        </div>
        <div class="flex justify-center mt-3 max-[560px]:flex-col">
          <div class="flex items-center">
            <MediaLikeComponent :identify_media_data="place.place" />
            <span class="text-xl mx-3">Like</span>
          </div>
        </div>
        <template v-if="place.place.additional_resources.length > 0">
          <button
            class="text-sm text-white bg-blue font-medium py-2 px-2 mt-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
            @click.stop="additionalSources($event, place.id)"
          >
            {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
          </button>
          <ul
            class="flex flex-col mt-5 gap-5 sm:text-center max-[640px]:text-center hidden"
            :id="'additionalSources-place-' + place.id"
          >
            <template
              v-for="(add_src, index) in place.place.additional_resources"
              :key="index"
            >
              <li>
                <span class="text-black underline">
                  <a
                    v-if="add_src.link"
                    :href="add_src.link"
                    target="_blank"
                    @click.stop=""
                    >{{ add_src.description }}</a
                  ></span
                >
              </li>
            </template>
          </ul>
        </template>
      </div>
      <div
        class="flex flex-col lg:ml-5 lg:w-[65%] max-[640px]:w-full max-[1023px]:mt-5"
      >
        <div class="h-[400px] overflow-y-scroll w-full">
          <h2 class="text-[28px] font-bold mb-2 max-[640px]:text-[24px]">
            {{ place.place.name }}
            <template v-if="place.place.google_map_link">
              <a
                :href="place.place.google_map_link"
                class="inline-flex"
                target="_blank"
                @click.stop=""
              >
                <svg
                  height="25px"
                  width="25px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 293.334 293.334"
                  xml:space="preserve"
                  fill="#3048c1"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g>
                        <path
                          style="fill: #3048c1"
                          d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z"
                        ></path>
                        <circle
                          style="fill: #3048c1"
                          cx="146.667"
                          cy="90.196"
                          r="21.756"
                        ></circle>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </template>
          </h2>
          <p class="text-black">
            <template v-if="place.place.groups.length > 0">
              <span class="text-sm font-semibold">Type:&nbsp;</span>
              <span class="text-sm opacity-40">{{
                place.place.groups.join(', ')
              }}</span>
            </template>
          </p>
          <template v-if="place.place.description">
            <p class="py-1">
              <span><b>Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ place.place.description }}
            </p>
          </template>
          <template v-if="place.description">
            <p class="py-1 mt-1">
              <span><b>Title Specific Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ place.description }}
            </p>
          </template>
          <div class="flex max-[376px]:flex-col my-3">
            <div
              class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1 mr-3 max-[376px]:mb-3 max-[376px]:w-full"
            >
              <span
                class="text-xs"
                :class="isReal ? 'text-blue ml-1' : 'text-black opacity-20'"
                >Real</span
              >
              <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
              <span
                class="text-xs"
                :class="isFiction ? 'text-blue ml-1' : 'text-black opacity-20'"
                >Fiction</span
              >
            </div>
            <div
              class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1"
            >
              <span
                class="text-xs"
                :class="isNative ? 'text-blue mr-1' : 'text-black opacity-20'"
                >Native
              </span>
              <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
              <span
                class="text-xs"
                :class="
                  isMentioned ? 'text-blue mr-1' : 'text-black opacity-20'
                "
                >Mentioned</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <template>
  <div class="shadow-md rounded-[40px] cursor-pointer">
    <img
      :src="place.place.image"
      :alt="place.place.name"
      class="w-full rounded-t-[40px] rounded-tr-[40px] max-h-[250px]"
    />
    <div class="md:p-6 max-[767px]:p-3">
      <div
        class="flex justify-center sm:max-lg:flex-col max-[640px]:flex-col h-[400px] overflow-y-scroll"
      >
        <div class="flex flex-col px-10 max-[414px]:px-5 items-center">
          <h2 class="text-[28px] max-[414px]:text-[24px] font-bold mt-4">
            {{ place.place.name }}
          </h2>
          <p class="pb-3">
            <template v-if="place.place.groups.length > 0">
              <span class="text-sm font-medium">Locations:&nbsp;</span>
              <span class="text-sm text-blue">{{
                place.place.groups.join(', ')
              }}</span>
            </template>
          </p>
          <div class="flex justify-center max-[560px]:flex-col">
            <div class="flex items-center">
              <MediaLikeComponent :identify_media_data="place.place" />
              <span class="text-xl mx-3">Like</span>
            </div>
          </div>
          <template v-if="place.place.description">
            <p class="py-1">
              <span><b>Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ place.place.description }}
            </p>
          </template>
          <template v-if="place.description">
            <p class="py-1 mt-1">
              <span><b>Title Specific Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ place.description }}
            </p>
          </template>
          <div class="flex max-[376px]:flex-col my-5">
            <div
              class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1 mr-3 max-[376px]:mb-3 max-[376px]:w-full"
            >
              <span
                class="text-xs"
                :class="isReal ? 'text-blue ml-1' : 'text-black opacity-20'"
                >Real</span
              >
              <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
              <span
                class="text-xs"
                :class="isFiction ? 'text-blue ml-1' : 'text-black opacity-20'"
                >Fiction</span
              >
            </div>
            <div
              class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1"
            >
              <span
                class="text-xs"
                :class="isNative ? 'text-blue mr-1' : 'text-black opacity-20'"
                >Native
              </span>
              <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
              <span
                class="text-xs"
                :class="
                  isMentioned ? 'text-blue mr-1' : 'text-black opacity-20'
                "
                >Mentioned</span
              >
            </div>
          </div>
          <div class="border-b-1 border-black mb-3 opacity-10 w-1/2"></div>

          <template v-if="place.place.additional_resources.length > 0">
            <button
              class="text-sm text-white bg-blue font-medium py-2 px-2 mt-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
              @click.stop="additionalSources($event, place.id)"
            >
              {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
            </button>
            <ul
              class="flex flex-col mt-5 gap-5 sm:text-center max-[640px]:text-center hidden"
              :id="'additionalSources-place-' + place.id"
            >
              <template
                v-for="(add_src, index) in place.place.additional_resources"
                :key="index"
              >
                <li>
                  <span class="text-black underline">
                    <a
                      v-if="add_src.link"
                      :href="add_src.link"
                      target="_blank"
                      @click.stop=""
                      >{{ add_src.description }}</a
                    ></span
                  >
                </li>
              </template>
            </ul>
          </template> -->
<!-- <div class="relative w-[178px] h-[4px] bg-silver mt-7 mb-2">
                  <span class="absolute right-0 w-[94px] h-[4px] bg-blue"></span>
              </div>  -->
<!-- <div class="flex w-[178px] mb-5">
                  <div class="w-[94px] text-center">
                      <span class="icon-building"></span>
                      <span>Map</span>
                  </div>
                  <div class="w-[94px] text-center">
                      <span class="icon-building"></span>
                      <span>Address</span>
                  </div>
              </div>
              <p class="text-sm text-blue font-semibold">
                  Hogwarts School of Witchcraft and Wizardry
              </p>
              <p class="text-xs text-black opacity-60 font-light text-center my-2">
                  1 Enchanted Lane Mystic Highlands, MH3 7WG <br />United
                  Wizarding Kingdom
              </p> -->
<!-- <template v-if="place.place.google_map_link">
            <a
              :href="place.place.google_map_link"
              target="_blank"
              class="py-2"
              @click.stop=""
            >
              <button
                class="text-sm text-white bg-blue font-semibold py-1 px-5 mt-1 mb-10 text-center rounded-full hover:text-blue hover:bg-white duration-500 border-1 hover:border-blue hover:border-1"
              >
                Open In Map
              </button>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template> -->

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
export default {
  name: 'PlaceCardComponent',
  components: {
    MediaLikeComponent,
  },
  data() {
    return {
      isReal: this.place.place.o_type === 'real',
      isFiction: this.place.place.o_type === 'fiction',
      isNative: this.place.source_type === 'native',
      isMentioned: this.place.source_type === 'mentioned',
      isLike: this.place.like,
      showAdditionalSources: false,
    };
  },
  props: {
    place: {
      type: Object,
    },
  },
  methods: {
    detail_navigation(id, component) {
      specific_page_routing(this.$router, id, component)
    },
    
    additionalSources(e, id) {
      const div_id = 'additionalSources-place-' + id;
      this.showAdditionalSources = !this.showAdditionalSources;
      document.querySelector('#' + div_id).classList.toggle('hidden');
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
