<template>
  <div class="w-full px-5 mx-auto">
    <!-- Header Start -->
    <HomeHeaders />
    <!-- Header End -->
    <template v-if="loading">
      <div class="loading-indicator">
        <div class="loader"></div>
      </div>
    </template>

    <template v-else>
      <div v-if="related_media_result" class="mx-auto">
        <div class="max-[640px]:px-5">
          <div class="flex">
            <button
              @click="goBack()"
              class="border-1 rounded-full border-blue px-10 py-4 mt-16 max-[376px]:px-5 max-[376px]:py-2 text-lg text-blue font-medium hover:border-1 hover:bg-blue hover:text-white duration-500"
            >
              Back
            </button>
          </div>
          <div
            class="flex flex-col bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5"
          >
            <DetailComponent />

            <div class="border-b-1 border-borderColor my-12"></div>
            <h2
              class="text-4xl max-[640px]:text-2xl font-bold w-full mb-9 max-[640px]:mb-5 text-blue"
            >
              {{ mediaSentence() }}
            </h2>
            <div
              v-if="related_media_result.result.results.length === 0"
              class="text-2xl font-semibold mb-5 text-center"
            >
              No Results Found
            </div>
            <template v-if="related_media_result_list">
              <div
                class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 sm:gap-4 max-[640px]:gap-y-4 mb-10"
              >
                <div
                  v-for="(item, index) in related_media_result_list"
                  :key="index"
                >
                  <CardComponent :card_data="item" />
                </div>
              </div>

              <div v-if="show_more_result" class="main-item">
                <div class="animated-background">
                  <div class="background-masker btn-divide-left"></div>
                </div>
              </div>
              <div
                v-if="
                  related_media_result.result.next != null &&
                  related_media_result
                "
                class="flex justify-center items-center cursor-pointer"
              >
                <SeeMoreIcon
                  @click="show_more_results(related_media_result.result.next)"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState, mapActions, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import DetailComponent from './component/detail_component.vue';
import CardComponent from '@/common_components/card/card_component.vue';
import { initializeSliders } from './specific_data_slider';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';

export default {
  name: 'SpecificData',

  components: {
    HomeHeaders,
    HomeFooter,
    DetailComponent,
    CardComponent,
    SeeMoreIcon,
  },

  computed: {
    ...mapState({
      entity_detail_result: (state) =>
        state.specific_data_store.entity_detail_result,
      related_media_result: (state) =>
        state.specific_data_store.related_media_result,
      related_media_result_list: (state) =>
        state.specific_data_store.related_media_result_list,
    }),
  },

  data() {
    return {
      loading: false,
      show_more_result: false,
      page: 1,
    };
  },

  async created() {
    this.reset_character_related_media_list();
    await this.fetchDataFromApis();
  },

  mounted() {
    initializeSliders();
  },

  methods: {
    ...mapActions('specific_data_store', [
      'character_entity_detail_api',
      'character_related_media_api',
    ]),
    ...mapMutations('specific_data_store', [
      'reset_character_related_media_list',
    ]),
    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.character_entity_detail_api({
            id: this.$route.query.id,
            type: this.$route.query.type,
          }),
          this.character_related_media_api({
            id: this.$route.query.id,
            page: this.page,
            type: this.$route.query.type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
    async show_more_results(page) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.character_related_media_api({
            id: this.$route.query.id,
            page: page,
            type: this.$route.query.type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    mediaSentence() {
      if (!this.entity_detail_result) {
        return '';
      }
      if (['media_credit'].includes(this.entity_detail_result.result.entity)) {
        return `Related media for this ${this.entity_detail_result.result.entity_display} are as follows:`;
      } else {
        return `This ${this.entity_detail_result.result.entity_display} appears in the following media:`;
      }
    },
  },
  beforeUnmount() {
    $('.result-media-slider').slick('unslick');
    $('.other-authors-slider').slick('unslick');
  },
};
</script>

<style>
.result-media-slider,
.other-authors-slider {
  overflow: hidden;
}

.slick-track {
  display: flex !important;
  padding: 5px 0;
}

/* the slides */
.slick-slide {
  margin: 0 20px;
  height: inherit !important;
}

/* the parent */
.slick-list {
  margin: 0 -20px;
}
</style>
