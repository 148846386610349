<template>
  <a>
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9999 26.1819L34.1111 16L37 18.9091L23.9999 32L11 18.9091L13.8889 16L23.9999 26.1819Z"
        fill="black"
      />
      <circle cx="23.5" cy="23.5" r="23" stroke="black" />
    </svg>
  </a>
</template>

<script>
export default {
  name: 'SeeMoreIcon',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
