import axios from 'axios';
import { APIs } from './apis_collection';

axios.defaults.baseURL = APIs.base_url;

axios.interceptors.request.use(
  function (config) {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      config.headers['Authorization'] = `Token ${storedToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
