import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const search_result_store = {
  namespaced: true,
  state: {
    query: '',
    media_type: '',
    search_result_data: null,
    search_result_data_list: [],
  },
  mutations: {
    set_user_data(state, payload) {
      state.query = payload.query;
      state.media_type = payload.media_type;
    },
    set_search_result_data(state, data) {
      state.search_result_data = data;
    },

    set_search_result_data_list(state, data) {
      state.search_result_data_list =
        state.search_result_data_list.concat(data);
    },
    reset_search_result_data_list(state) {
      state.search_result_data_list = [];
    },
  },
  actions: {
    search_result_api({ commit }, payload) {
      let apiUrl;
      commit('set_user_data', payload);
      const queryParam =
        payload.search_type === 'search_query' ||
        payload.search_type === 'favorite_search_query'
          ? `&q=${payload.query}`
          : '';
      if (payload.search_type === 'recently_viewed') {
        apiUrl = `${APIs.recent_views_api}?media_type=${
          payload.media_type
        }${queryParam}&page=${String(payload.page)}`;
      } else if (payload.search_type === 'trending_media') {
        apiUrl = `${APIs.trending_media_api}?media_type=${
          payload.media_type
        }${queryParam}&page=${String(payload.page)}`;
      } else if (payload.search_type === 'featured_media') {
        apiUrl = `${APIs.feature_media_api}?media_type=${
          payload.media_type
        }${queryParam}&page=${String(payload.page)}`;
      } else if (payload.search_type === 'most_viewed') {
        apiUrl = `${APIs.most_viewed_api}?media_type=${
          payload.media_type
        }${queryParam}&page=${String(payload.page)}`;
      } else if (payload.search_type === 'search_query') {
        apiUrl = `${APIs.search_result_api}?media_type=${
          payload.media_type
        }${queryParam}&page=${String(payload.page)}`;
      } else if (payload.search_type === 'favorite_search_query') {
        apiUrl = `${APIs.all_favorite_api}?media_type=${
          payload.media_type
        }${queryParam}&page=${String(payload.page)}`;
      } else if (payload.search_type === 'favorite_media') {
        apiUrl = `${APIs.all_favorite_api}?media_type=${
          payload.media_type
        }${queryParam}&page=${String(payload.page)}`;
      } else {
        return '';
      }

      return new Promise((resolve, reject) => {
        axios
          .get(apiUrl)
          .then((response) => {
            commit('set_search_result_data', response.data);
            commit('set_search_result_data_list', response.data.result.results);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response);
          });
      });
    },
  },
};

export default search_result_store;
