import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const profile_setting_store = {
  namespaced: true,
  state: {
    profile_data: null,
  },
  mutations: {
    set_profile_data(state, data) {
      state.profile_data = data;
    },
  },
  actions: {
    fetch_profile({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.profile_api)
          .then((response) => {
            if (response.data.success) {
              commit('set_profile_data', response.data.result);
              localStorage.setItem(
                'profile_data',
                JSON.stringify(response.data),
              );
              resolve(response.data.result);
            } else {
              reject(response.data.result);
            }
          })
          .catch((error) => {
            console.error('Error fetching profile:', error);
            reject(error);
          });
      });
    },
    update_profile({ commit }, { id, profile }) {
      return new Promise((resolve, reject) => {
        const updateProfileUrl = APIs.update_profile_api.replace('{id}', id);
        axios
          .put(updateProfileUrl, profile)
          .then((response) => {
            commit('set_profile_data', response.data);
            localStorage.setItem('profile_data', JSON.stringify(response.data));
            resolve(response.data);
          })
          .catch((error) => {
            console.error('Error updating profile:', error);
            reject(error);
          });
      });
    },
    change_password({ commit }, { passwordData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(APIs.change_password_api, passwordData)
          .then((response) => {
            commit('set_profile_data', response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default profile_setting_store;
