import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const home_search_store = {
  namespaced: true,
  state: {
    home_search_result: null,
    show_result: [],
  },
  mutations: {
    set_home_search_result(state, data) {
      state.home_search_result = data;
    },

    show_more_results(state, data) {
      state.show_result = data;
    },

    reset_home_search_result_list(state) {
      state.media_result = [];
    },
    reset_home_search_result(state) {
      state.home_search_result = null;
    },
  },
  actions: {
    home_search_result_api({ commit }, payload) {
      console.log('home_search_result_api payload:', payload);
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.home_search_api + '?q=' + payload.search_query)
          .then((response) => {
            commit('set_home_search_result', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    show_more_results_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            APIs.home_search_api +
              '?q=' +
              payload.query +
              '&entity=' +
              payload.entity_type +
              '&page=' +
              payload.page,
          )
          .then((response) => {
            commit('show_more_results', response.data.result);
            console.log('fucnkdsnkld: ', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },

    satisfy_curiosity_option_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.satisfy_curiosity_option_api + '?q=' + payload.query)
          .then((response) => {
            commit('set_satisfy_curiosity_option', response.data.result);
            resolve(response);
          })
          .catch((error) => {
            console.error('Error hitting feature_media_api API:', error);
            reject(error);
          });
      });
    },
  },
};

export default home_search_store;
