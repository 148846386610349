<template>
  <div
    id="popupContent"
    class="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
  >
    <div
      class="relative h-screen max-[414px]:h-auto flex justify-center items-center"
    >
      <div
        class="relative bg-white px-12 py-14 max-[640px]:px-10 rounded-3xl w-[1080px] max-[1100px]:w-full"
      >
        <div class="absolute top-7 right-7">
          <span class="icon-cross cursor-pointer" @click="closePopup()"></span>
        </div>
        <div
          class="flex flex-col justify-center items-center lg:pl-10 py-10 bg-white rounded-3xl font-inter text-center lg:w-[927px]"
        >
          <h2 class="text-4xl text-blue font-bold mb-4 max-[767px]:text-2xl">
            {{ heading_text }}
          </h2>
          <p class="text-lg text-black opacity-60 mb-11">
            You can keep using our most powerful features like timeline and
            Custom Fields.
            <br />
            Join Discovery to see complete data page details of different media
          </p>
          <button
            @click="create_account_navigation()"
            class="bg-blue border-1 text-sm font-medium text-white px-11 py-3 rounded-full hover:bg-white hover:text-blue hover:border-1 hover:border-blue duration-500"
          >
            Sign up
          </button>
          <div class="cursor-pointer" @click="login_account_navigation()">
            <a
              class="text-base text-blue underline mt-5 inline-block hover:no-underline"
              >Already have an account? Login</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'LoginPopup',
  props: {
    page: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState({
      msg_to_show: (state) => state.home_logout_store.msg_to_show,
    }),
    heading_text() {
      return this.msg_to_show;
    },
  },

  data() {
    return {};
  },
  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup']),

    create_account_navigation() {
      this.$router.push({ name: 'create-account' });
    },

    login_account_navigation() {
      this.$router.push({ name: 'login-account' });
    },

    closePopup() {
      this.set_show_login_popup(false);
      document.querySelector('#popupContent').classList.add('hidden');
    },
  },
};
</script>

<style></style>
