import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const create_account_store = {
  namespaced: true,
  state: {
    user_name: '',
    age: '',
    email: '',
    password: '',
    terms_and_conditions_accepted: false,
  },
  mutations: {
    set_user_data(state, payload) {
      state.user_name = payload.full_name;
      state.age = payload.age;
      state.email = payload.email;
      state.password = payload.password;
      state.terms_and_conditions_accepted =
        payload.terms_and_conditions_accepted;
    },
  },
  actions: {
    create_account_api({ commit }, payload) {
      commit('set_user_data', payload);
      return new Promise((resolve, reject) => {
        axios
          .post(APIs.register_api, payload)
          .then((response) => {
            localStorage.setItem('token', response.data.result.token);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default create_account_store;
