import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const related_media_store = {
  namespaced: true,
  state: {
    related_media_result: null,
    related_media_result_list: [],
    group_result: null,
  },
  mutations: {
    set_related_media_result(state, data) {
      state.related_media_result = data;
    },
    set_related_media_result_list(state, data) {
      state.related_media_result_list =
        state.related_media_result_list.concat(data);
    },
    reset_related_media_result(state) {
      state.related_media_result_list = [];
    },
  },
  actions: {
    related_media_show_result_api({ commit }, payload) {
      console.log('payload: ', payload);
      return new Promise((resolve, reject) => {
        const apiUrl = `${APIs.related_media_api.replace(
          '{id}',
          payload.id,
        )}?per_page=4&page=${payload.page}`;

        axios
          .get(apiUrl)
          .then((response) => {
            commit('set_related_media_result', response.data.result);
            commit(
              'set_related_media_result_list',
              response.data.result.results,
            );
            resolve(response);
          })
          .catch((error) => {
            console.error(
              'Error hitting related_media_show_result_api API:',
              error,
            );
            reject(error);
          });
      });
    },
  },
};

export default related_media_store;
