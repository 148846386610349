<template>
  <LoginPopup v-if="show_login_popup" />
  <div class="w-full px-5 mx-auto">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
    <template v-if="loading">
      <div class="loading-indicator">
        <div class="loader"></div>
      </div>
    </template>
    <template v-else>
      <div v-if="home_search_result.result" class="mx-auto font-inter">
        <div
          class="flex max-[767px]:flex-col justify-between max-[767px]:justify-center items-center max-[767px]:gap-y-5"
        >
          <button
            @click="goBack()"
            class="border-1 rounded-full border-blue px-10 py-4 max-[376px]:px-5 max-[376px]:py-2 text-lg text-blue font-medium hover:border-1 hover:bg-blue hover:text-white duration-500"
          >
            Back
          </button>
          <h2 class="text-4xl max-[640px]:text-2xl font-bold">
            Search results for “{{ search_topic_display }}”
          </h2>
          <a
            class="flex items-center max-[991px]:mb-5 cursor-pointer"
            @click="navigation_curiosity()"
          >
            <span class="text-xs text-blue underline mr-2"
              >Satisfy My Curiosity</span
            >
            <span>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0037 3.93955L2.39712 12.5312L0.98291 11.1195L9.5895 2.52779H2.00373V0.53125H13.0037V11.5122H11.0037V3.93955Z"
                  fill="#3048C1"
                />
              </svg>
            </span>
          </a>
        </div>
        <div
          v-if="home_search_result.result.has_results"
          class="max-[640px]:px-5"
        >
          <template
            v-for="(value, key) in home_search_result.result"
            :key="key"
          >
            <template v-if="!exclude_params.includes(key) && value.count != 0">
              <SearchedResults
                :key_prop="key"
                :value_prop="value"
                :search_topic_display="search_topic_display"
              />
            </template>
          </template>
        </div>
        <div v-else class="text-2xl font-semibold mb-5 text-center">
          No Results Found
        </div>
      </div>
      <div
        class="recently-bottom-border border-b-1 border-borderColor mt-10 mb-7"
      ></div>
    </template>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { logout_messages } from '@/common_variables/logout_messages';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import SearchedResults from './components/searched_results_component.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';

export default {
  name: 'HomeSearch',
  components: {
    HomeHeader,
    HomeFooter,
    SearchedResults,
    LoginPopup,
  },

  async created() {
    this.reset_home_search_result_list();
    await this.fetchDataFromApis(this.$route.params.search_topic);
  },

  data() {
    return {
      isLogin: false,
      loading: false,
      show_more_result: false,
      page: 1,
      search_topic_display: this.$route.params.search_topic,
      previously_selected_topic: null,
      exclude_params: ['has_results', 'search_query'],
    };
  },

  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
  },

  computed: {
    ...mapState({
      home_search_result: (state) => state.home_search_store.home_search_result,
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
    }),
  },

  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),
    ...mapActions('home_search_store', ['home_search_result_api']),
    ...mapMutations('home_search_store', ['reset_home_search_result_list']),

    goBack() {
      this.$router.go(-1);
    },

    navigation_curiosity() {
      if (this.isLogin) {
        this.$router.push({
          name: 'search-satisfy',
          params: {
            search_topic: this.search_topic_display,
            search_interest: 'null',
          },
        });
      } else {
        this.set_show_login_popup(true);
        this.set_msg(logout_messages.specific_data_message);
      }
    },

    async fetchDataFromApis(search_topic) {
      this.loading = true;
      try {
        await Promise.all([
          this.home_search_result_api({
            search_query: search_topic,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
