<template>
    <div class="w-full mx-auto px-5 font-inter">
        <HomeHeaders />
        <section>
            <div v-if="loading" class="loading-indicator">
                <div class="loader"></div>
            </div>
            <div v-else class="bg-[#e4eaf8] font-inter">
                <h2 class="text-[45px] font-semibold text-center py-16">
                    Badges
                </h2>
                <!-- Badges Start -->
                <div v-if="badge_stats_data.badge.length > 0" class="mt-6">
                    <ul class="grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 w-full">
                        <BadgeStatsComponent 
                            v-for="(badge_stats_data, index) in badge_stats_data.badge" 
                            :key="index" 
                            :stats_data="badge_stats_data"
                        />
                        
                    </ul>
                </div>
                <div v-else class="mt-6">
                    No Badge Available
                </div>
                <!-- Badges End -->
            </div>
            <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"></div>
        </section>

        <HomeFooter />
    </div>

</template>


<script>
import { mapActions, mapState } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import BadgeStatsComponent from './component/badge_stats_component.vue'

export default {
    name: 'StatsScreen',
    components: {
        HomeHeaders,
        HomeFooter,
        BadgeStatsComponent,
    },

    async created() {
        await this.fetchDataFromApis();
    },

    computed: {
        ...mapState({
            badge_stats_data: (state) => state.stats_store.badge_stats_data,
        }),
    },

    data() {
        return {
            loading: true,
        };
    },

    methods: {
        ...mapActions('stats_store', [
            'badge_stats_api',
        ]),

        async fetchDataFromApis() {
            this.loading = true;
            try {
                await Promise.all([
                    this.badge_stats_api(),
                ]);
            } catch (error) {
                console.error('Error fetching data from APIs:', error);
            } finally {
                this.loading = false;
            }
        },
    }


}
</script>

<style>
@import '../../css/style.css';
@import '../../css/discovery.css';
</style>