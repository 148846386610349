<template>
  <div class="w-full px-5 mx-auto">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->

    <div v-if="!find_media_result" class="loading-indicator">
      <div class="loader"></div>
    </div>

    <div v-else class="mx-auto font-inter">
      <div class="max-[640px]:px-5">
        <div
          class="flex items-center max-[640px]:flex-col bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5"
        >
          <img
            src="../../images/bulb.png"
            alt="Bulb"
            class="max-[640px]:mb-3"
          />
          <template v-if="find_media_result_list.length > 0">
            <p
              class="text-2xl font-bold ml-14 max-[640px]:ml-7 max-[640px]:text-lg"
            >
              All right! I've come up with a list of
              {{ getDisplayMediaType(media_type) }} that could be the one you're
              thinking of. Can you let me know if any of these are the one
              you're looking for?
            </p>
          </template>
          <template v-else>
            <div class="flex flex-col ml-14 max-[640px]:ml-7">
              <p class="text-2xl font-bold max-[640px]:text-lg mb-4">
                I couldn't find anything for {{ this.$route.params.q }}.
              </p>
              <p class="text-xl text-[#404558] opacity-75">
                <!-- you can use the
                'Feedback' button to send a request to our curator team to add
                this topic, or -->
                Press back to adjust your search. Alternatively, you can create
                your own Media record for this topic.
              </p>
            </div>
          </template>
        </div>

        <template v-if="find_media_result_list.length > 0">
          <div
            class="flex flex-col bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5"
          >
            <h2
              class="text-4xl max-[640px]:text-2xl font-bold text-center w-full mb-9 max-[640px]:mb-5"
            >
              Result Media Based On Activity
            </h2>

            <div
              class="grid md:grid-cols-3 sm:grid-cols-2 sm:gap-4 max-[640px]:gap-y-4 mb-10 lg:grid-cols-4"
            >
              <template
                v-for="(item, index) in find_media_result_list"
                :key="index"
              >
                <div class="flex flex-col">
                  <CardComponent :card_data="item" />
                </div>
              </template>
            </div>
            <div v-if="loading" class="main-item">
              <div class="animated-background">
                <div class="background-masker btn-divide-left"></div>
              </div>
            </div>
            <div
              v-if="find_media_result.result.next !== null"
              class="flex justify-center items-center cursor-pointer"
            >
              <SeeMoreIcon @click="see_more_result()" />
            </div>
          </div>
        </template>

        <template v-else>
          <div
            class="flex flex-col items-center bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5"
          >
            <img
              src="../../images/result-not-found.png"
              alt="Result Not Found"
              class="max-w-[256px]"
            />
            <p class="text-4xl font-bold opacity-40 mt-5">Result Not Found</p>
          </div>
        </template>

        <div class="flex max-[767px]:flex-col">
          <button
            @click="go_back"
            class="border-1 rounded-full border-blue px-12 py-3 mr-7 max-[767px]:mr-0 max-[767px]:mb-5 max-[376px]:px-7 max-[376px]:py-1 max-[376px]:mr-2 text-4xl max-[640px]:text-2xl text-blue font-medium hover:border-1 hover:bg-blue hover:text-white duration-500"
          >
            Back
          </button>
          <!-- <button
            class="border-1 rounded-full border-red-600 bg-red-600 px-12 py-3 max-[376px]:px-7 max-[376px]:py-1 text-4xl max-[640px]:text-2xl text-white font-medium hover:border-1 hover:bg-white hover:border-red-600 hover:text-red-600 duration-500"
            type="button">
            None of these are correct
          </button> -->
          <button
            v-if="!result"
            @click="create_form()"
            class="lg:text-4xl md:text-3xl sm:text-2xl max-[640px]:text-2xl text-white font-medium lg:px-12 lg:py-3 md:px-9 md:py-2 sm:px-7 sm:py-2 max-[640px]:px-7 max-[640px]:py-2 md:mr-5 max-[376px]:mr-2 max-[376px]:px-7 max-[376px]:py-2 border-1 rounded-full border-blue bg-blue hover:border-1 hover:bg-white hover:border-blue hover:text-blue duration-500"
          >
            Create New
          </button>
        </div>
      </div>
    </div>
    <div
      class="recently-bottom-border border-b-1 border-borderColor mt-10 mb-7"
    ></div>

    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState, mapActions, mapMutations } from 'vuex';
import CardComponent from '@/common_components/card/card_component.vue';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue'
import { all_media_type_values } from '@/common_variables/media_type';

export default {
  name: 'ShowoffInitialResult',
  components: {
    HomeHeader,
    HomeFooter,
    CardComponent,
    SeeMoreIcon,
  },
  async created() {
    this.reset_find_media_result_list()
    await this.fetchDataFromApis(this.page);
  },

  computed: {
    ...mapState({
      find_media_result: (state) =>
        state.showoff_initial_result_store.find_media_result,
      find_media_result_list: (state) =>
        state.showoff_initial_result_store.find_media_result_list,
    }),
  },

  data() {
    return {
      text: true,
      loading: true,
      media_type: this.$route.params.media_type,
      page: 1,
    };
  },
  methods: {
    ...mapActions('showoff_initial_result_store', ['get_find_media_result']),
    ...mapMutations('showoff_initial_result_store', ['reset_find_media_result_list']),

    create_form() {
      this.$router.push({ name: 'create-new-media' });
    },

    getDisplayMediaType(media_type) {
      return all_media_type_values[media_type]['plural'];
    },
    see_more_result(){
      if(this.find_media_result.result.next != null) {
        this.fetchDataFromApis(this.find_media_result.result.next)
      }
    },
    async fetchDataFromApis(page) {
      this.loading = true;
      try {
        await Promise.all([
          this.get_find_media_result({
            find_by: this.$route.params.find_by,
            query: this.$route.params.q,
            media_type: this.$route.params.media_type,
            page: page
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },

    go_back() {
      this.$router.go(-1);
    },
  },

  beforeUnmount() {
    $('.recently-viewed-slider').slick('unslick');
  },
};
</script>

<style>
@import '../../css/slick.css';
@import '../../css/slick-theme.css';
@import '../../css/icomoon.css';
@import '../../css/style.css';
@import '../../css/discovery.css';

.recently-viewed-slider .slick-track {
  display: flex;
  gap: 3rem;
}
</style>
