import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const login_account_store = {
  namespaced: true,
  state: {
    email: '',
    password: '',
  },
  mutations: {
    set_user_data(state, payload) {
      state.email = payload.email;
      state.password = payload.password;
    },
  },
  actions: {
    login_account_api({ commit }, payload) {
      // localStorage.clear();
      commit('set_user_data', payload);
      return new Promise((resolve, reject) => {
        axios
          .post(APIs.login_api, payload)
          .then((response) => {
            localStorage.setItem('token', response.data.result.token);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default login_account_store;
