export const all_media_type_values = {
    book: {
        media_type_key: 'book',
        singular: 'Book',
        plural: 'Books',
        creator_label: 'AUTHOR',
        released_label: 'PUBLISHED',
        identifier_label: 'ISBN',
        display_progress: 'Reading Progress'
    },
    movie: {
        media_type_key: 'movie',
        singular: 'Movie',
        plural: 'Movies',
        creator_label: 'DIRECTOR',
        released_label: 'RELEASED',
        identifier_label: 'UPC',
        display_progress: 'Watch Progress'
    },
    tv_show: {
        media_type_key: 'tv_show',
        singular: 'TV Show',
        plural: 'TV Shows',
        creator_label: 'CREATOR',
        released_label: 'RELEASED',
        identifier_label: 'UPC',
        display_progress: 'Watch Progress'
    },
    podcast: {
        media_type_key: 'podcast',
        singular: 'Podcast',
        plural: 'Podcasts',
        creator_label: 'HOST',
        released_label: 'RELEASED',
        identifier_label: 'UPC',
        display_progress: 'Listening Progress'
    },
    music: {
        media_type_key: 'music',
        singular: 'Music',
        plural: 'Music',
        creator_label: 'COMPOSER/PERFORMER',
        released_label: 'RELEASED',
        identifier_label: 'UPC or ISRC',
        display_progress: 'Listening Progress'
    },
    game: {
        media_type_key: 'game',
        singular: 'Game',
        plural: 'Games',
        creator_label: 'DEVELOPER',
        released_label: 'RELEASED',
        identifier_label: 'UPC',
        display_progress: 'Play Progress'
    },
    theater: {
        media_type_key: 'theater',
        singular: 'Theater',
        plural: 'Theater',
        creator_label: 'PLAYWRITE',
        released_label: 'PERFORMED',
        identifier_label: '',
        display_progress: 'Attend Progress'
    },
    artifact: {
        media_type_key: 'artifact',
        singular: 'Artifact',
        plural: 'Artifacts',
        creator_label: 'CREATOR',
        released_label: 'CREATED',
        identifier_label: 'Museum Number',
        display_progress: 'Seen Progress'
    }
};
