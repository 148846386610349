import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const stats_store = {
    namespaced: true,
    state: {
        badge_stats_data: null,
    },
    mutations: {
        set_badge_stats_data(state, data) {
            state.badge_stats_data = data
        }
    },
    actions: {
        badge_stats_api({ commit }) {
            return new Promise((resolve, reject) => {
              axios
                .get(APIs.badge_stats_api)
                .then((response) => {
                  commit('set_badge_stats_data', response.data.result);
                  resolve(response);
                })
                .catch((error) => {
                  console.error('Error hitting set_badge_stats_data API:', error);
                  reject(error);
                });
            });
          },
    }
};

export default stats_store;