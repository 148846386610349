<template
  src="../../screens/create_account/create_account_view.html"
></template>

<script>
export default {
  name: 'CreateAccount',
  data() {
    return {
      user_name: '',
      age: '',
      email: '',
      password: '',
      passwordFieldType: 'password',
      terms_and_conditions_accepted: true,
    };
  },
  methods: {
    login_account_navigate() {
      this.$router.push({ name: 'login-account' });
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    set_user_info() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.user_name.length == 0) {
        this.$toast.open({
          message: 'Enter your name.',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else if (this.age.length == 0) {
        this.$toast.open({
          message: 'Enter your age.',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else if (!emailPattern.test(this.email)) {
        this.$toast.open({
          message: 'Invalid Email format!. Try Again',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else if (this.password.length < 8) {
        this.$toast.open({
          message: 'Invalid Password!. Try Again',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else {
        this.$store
          .dispatch('create_account_store/create_account_api', {
            full_name: this.user_name,
            age: this.age,
            email: this.email,
            password: this.password,
            terms_and_conditions_accepted: this.terms_and_conditions_accepted,
          })
          .then(() => {
            this.$toast.open({
              message: '"Account created successfully. You are now logged in!"',
              type: 'success',
              duration: 1000 * 5,
              dismissible: true,
            });
            this.$router.push({ name: 'home' });
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              type: 'error',
              duration: 1000 * 5,
              dismissible: true,
            });
          });
      }
    },
  },
};
</script>

<style src="../../css/style.css" scoped></style>
