<template>
  <div 
    @click="
      detail_navigation(
        purchase_oppurtunity.purchase_oppurtunity.id,
        purchase_oppurtunity.purchase_oppurtunity.entity,
      )
    " 
    class="text-center bg-white rounded-xl cursor-pointer"
  >
    <div class="flex flex-col items-center">
      <div
        class="relative h-[290px] w-[250px] max-[767px]:mx-auto max-[767px]:my-5 border-1 border-borderColor rounded-xl"
      >
        <img
          :src="purchase_oppurtunity.purchase_oppurtunity.image"
          :alt="purchase_oppurtunity.purchase_oppurtunity.name"
          class="max-[767px]:w-full rounded-xl absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto p-2"
        />
      </div>
      <h2 class="text-[28px] max-[640px]:text-[24px] font-bold mb-3">
        {{ purchase_oppurtunity.purchase_oppurtunity.name }}
      </h2>
      <p class="text-base font-medium text-[#585858] opacity-60">FROM</p>
      <p class="text-xl max-[640px]-text-lg font-bold">MEDIA</p>
      <template v-if="purchase_oppurtunity.purchase_oppurtunity.link">
        <a :href="purchase_oppurtunity.purchase_oppurtunity.link" target="_blank"
          ><button
            class="border-2 border-blue text-2xl max-[640px]:text-[20px] text-blue hover:bg-blue hover:text-white hover:border-2 rounded-full px-3 py-1 my-4 duration-500"
          >
            Purchase
          </button></a
        >
      </template>
      <div class="flex items-center justify-center mb-10">
        <MediaLikeComponent :identify_media_data="purchase_oppurtunity.purchase_oppurtunity" />
        <span class="text-xl mx-3">Like</span>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';

export default {
  name: 'PurchaseCardActivityComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    purchase_oppurtunity: {
      type: Object,
    },
  },
  data() {
    return {
      isLike: this.purchase_oppurtunity.purchase_oppurtunity.like,
    };
  },

  methods:{
    detail_navigation(id, component) {
      specific_page_routing(this.$router, id, component)
    },
  }
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
