import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const add_quote_store = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    post_add_quote_data_api(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APIs.quote_api}/`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default add_quote_store;
