<template>
  <LoginPopup v-if="show_login_popup" />
  <div
    id="modelConfirm"
    class="fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
  >
    <div class="relative h-screen flex justify-center items-center">
      <div
        class="bg-white px-28 py-16 rounded-3xl font-inter text-center w-[784px]"
      >
        <h2 class="text-4xl text-blue font-bold mb-4">Ready to dive deeper?</h2>
        <p class="text-lg text-black opacity-60 mb-11">
          Sign up now to unlock exclusive access and start curating data about
          your favorite media.
        </p>
        <button
          class="bg-blue border-1 text-sm font-medium text-white px-11 py-3 rounded-full hover:bg-white hover:text-blue hover:border-1 hover:border-blue duration-500"
          @click="closeModal()"
        >
          Sign upb
        </button>
      </div>
    </div>
  </div>
  <div class="w-full px-5 mx-auto">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->

    <div class="relative mx-auto font-inter">
      <!-- <div
        class="fixed z-50 w-[982px] max-[1024px]:w-full text-center mt-10 bg-white py-5"
      >
        <h3 class="text-2xl font-semibold">Coming Soon!</h3>
        <p>Stay Tuned For Updates</p>
      </div> -->
      <div class="max-[640px]:px-5">
        <div
          class="flex items-center max-[640px]:flex-col bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5"
        >
          <img
            src="../../images/bulb.png"
            alt="Bulb"
            class="max-[640px]:mb-3"
          />
          <p
            class="text-2xl font-bold ml-14 max-[640px]:ml-7 max-[640px]:text-lg max-[640px]:text-center"
          >
            Share my knowledge tests on a certain Media.
          </p>
        </div>
        <p class="text-2xl font-bold mb-10">
          Please try to find the {{ media_type_display }} by
          {{ media_option[media_selected_key][0]
          }}{{
            media_option[media_selected_key][1]
              ? ` or ${media_option[media_selected_key][1]}`
              : ''
          }}.
        </p>
        <p class="text-2xl font-bold my-5">Select Media Type</p>
        <div class="flex flex-wrap mb-11">
          <button
            v-for="(media_type, key) in media_type_dic"
            :key="key"
            @click="selectButton(media_type, key)"
            :class="[
              'text-xl border-1 rounded-full py-2 px-7 mr-4 mb-3 text-center',
              media_type === media_type_display
                ? 'text-white bg-blue border-blue'
                : 'text-blue border-blue',
            ]"
          >
            {{ media_type }}
          </button>
        </div>

        <form class="mb-14" onsubmit="return false;">
          <div class="w-full mb-9 relative">
            <span
              class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] text-[#ADB5BD] icon-updownarrow pointer-events-none"
            ></span>
            <label for="interest" class="text-2xl font-bold max-[640px]:text-lg"
              >Identify By<span class="text-red-500">*</span></label
            >
            <select
              v-if="media_selected_key"
              v-model="selected_option"
              class="border-1 border-borderColor rounded-full text-[#53585c] text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2 appearance-none"
            >
              <option
                v-for="(option, index) in media_option[media_selected_key]"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <div class="w-full mb-9 relative">
            <span
              class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] max-[370px]:top-[80px] text-[#ADB5BD] icon-scan pointer-events-none"
            ></span>
            <label
              for="interest"
              class="text-2xl font-bold max-[640px]:text-lg"
            >
              <p>Please Enter {{ selected_option }}</p>
              <input
                v-model="query"
                type="text"
                class="border-1 border-borderColor rounded-full text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
                :placeholder="'Enter ' + selected_option"
              />
            </label>
          </div>
          <div class="flex">
            <button
              class="border-1 rounded-full border-blue bg-blue px-12 py-3 max-[376px]:px-7 max-[376px]:py-1 text-4xl max-[640px]:text-2xl text-white font-medium hover:border-1 hover:bg-white hover:border-blue hover:text-blue duration-500"
              type="button"
              @click="navigate_showoff_initial_result()"
            >
              Next
            </button>
          </div>
        </form>
      </div>
      <div
        class="recently-bottom-border border-b-1 border-borderColor mt-10 mb-7"
      ></div>

      <!-- Footer Start -->
      <HomeFooter />
      <!-- Footer End -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';
import { logout_messages } from '@/common_variables/logout_messages';
import { all_media_type_values } from '@/common_variables/media_type';

export default {
  name: 'ShowoffKnowledge',
  components: {
    HomeHeader,
    HomeFooter,
    LoginPopup,
  },
  computed: {
    ...mapState({
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
    }),
    media_type_dic(){
      return Object.keys(all_media_type_values).reduce((acc, key) => {
              acc[key] = all_media_type_values[key].singular;
        return acc; }, {})
    },
    media_option(){
      return  Object.keys(all_media_type_values).reduce((acc, key) => {
                acc[key] = [ 'Title' , all_media_type_values[key].identifier_label];
                if (key === all_media_type_values['theater']['media_type_key']){
                  acc[key].pop()
                }
                return acc; }, {})
      
    }
  },
  data() {
    return {
      isLogin: false,
      media_type_display: all_media_type_values['book']['singular'],
      media_selected_key: all_media_type_values['book']['media_type_key'],
      selected_option: 'Title',
      query: '',
    };
  },

  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
      this.set_show_login_popup(true);
      this.set_msg(logout_messages.show_off_knowledge);
    } else {
      this.isLogin = true;
    }
  },


  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    handleInput(event) {
      if (this.selected_option !== 'Title') {
        // Replace non-numeric characters
        const value = event.target.value.replace(/[^0-9]/g, '');
        this.query = value;
      } else {
        // Allow all characters for "Title"
        this.query = event.target.value;
      }
    },

    selectButton(media_type, key) {
      this.media_type_display = media_type;
      this.media_selected_key = key;
      this.query = '';
      this.selected_option = this.media_option[key][0];
    },

    openModal() {
      document.querySelector('#modelConfirm').classList.remove('hidden');
    },
    closeModal() {
      document.querySelector('#modelConfirm').classList.add('hidden');
    },
    navigate_showoff_initial_result() {
      if (
        localStorage.getItem('token') === undefined ||
        localStorage.getItem('token') === null ||
        localStorage.getItem('token') === ''
      ) {
        this.set_msg(logout_messages.show_off_knowledge);
        this.set_show_login_popup(true);
        return;
      } else if (this.query.length === 0) {
        this.$toast.open({
          message: `${this.selected_option} cannot be empty`,
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }

      let find_by = '';
      if (this.selected_option === 'Title') {
        find_by = 'title';
      } else {
        find_by = 'identifier';
      }
      console.log(find_by);
      this.$router.push({
        name: 'showoff-initial-result',
        params: {
          find_by: find_by,
          media_type: this.media_selected_key,
          q: this.query,
        },
      });
    },
  },
};
</script>

<style>
@import '../../css/icomoon.css';
@import '../../css/style.css';
@import '../../css/discovery.css';

.recently-viewed-slider .slick-track {
  display: flex;
  gap: 3rem;
}
</style>
