import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const review_quote_store = {
  namespaced: true,
  state: {
    review_data: null,
    quote_data: null,
  },
  mutations: {
    set_review_data(state, data) {
      state.review_data = data;
    },
    set_quote_data(state, data) {
      state.quote_data = data;
    },
  },
  actions: {
    get_review_data_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.review_api}/${payload.id}/`)
          .then((response) => {
            commit('set_review_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    post_review_data_api(_, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios
          .post(`${APIs.review_api}/`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    get_quote_data_api({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APIs.quote_api}/${payload.id}/`)
          .then((response) => {
            commit('set_quote_data', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default review_quote_store;
