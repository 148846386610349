<template>
  <div
    @click="
      detail_navigation(
        career_interest.career_interest.id,
        career_interest.career_interest.entity,
      )
    "
    class="shadow-md border-1 border-borderColor py-10 px-5 rounded-3xl cursor-pointer"
  >
    <div class="flex sm:max-lg:flex-col max-[640px]:flex-col">
      <div
        class="flex flex-col lg:w-[35%] max-[640px]:w-full sm:max-lg:items-center max-[640px]:items-center"
      >
        <div
          class="relative xl:h-[260px] lg:h-[161px] max-[1023px]:h-[285px] max-[1023px]:w-[210px] border-1 border-borderColor rounded-xl"
        >
          <img
            :src="career_interest.career_interest.image"
            :alt="career_interest.career_interest.name"
            class="rounded-xl absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto p-2"
          />
        </div>
        <div class="flex justify-center mt-3 max-[560px]:flex-col">
          <div class="flex items-center">
            <MediaLikeComponent
              :identify_media_data="career_interest.career_interest"
            />
            <span class="text-xl mx-3">Like</span>
          </div>
        </div>
        <template
          v-if="career_interest.career_interest.additional_resources.length > 0"
        >
          <button
            class="text-sm text-white bg-blue font-medium py-2 px-2 mt-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
            @click.stop="additionalSources($event, career_interest.id)"
          >
            {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
          </button>
          <ul
            class="flex flex-col mt-5 gap-5 sm:text-center max-[640px]:text-center hidden"
            :id="'additionalSources-cri-' + career_interest.id"
          >
            <template
              v-for="(add_src, index) in career_interest.career_interest
                .additional_resources"
              :key="index"
            >
              <li>
                <span class="text-black underline">
                  <a
                    v-if="add_src.link"
                    :href="add_src.link"
                    target="_blank"
                    @click.stop=""
                    >{{ add_src.description }}</a
                  ></span
                >
              </li>
            </template>
          </ul>
        </template>
      </div>
      <div
        class="flex flex-col lg:ml-5 lg:w-[65%] max-[640px]:w-full max-[1023px]:mt-5"
      >
        <div class="h-[400px] overflow-y-scroll">
          <h2 class="text-[28px] font-bold mb-2 max-[640px]:text-[24px]">
            {{ career_interest.career_interest.name }}
          </h2>
          <!-- <p class="text-sm mt-3 h-[100px] overflow-y-auto pr-3">
                    <template v-if="career_interest.career_interest.description">
                        <p class="py-1"><b>Description:</b></p>
                        {{ career_interest.career_interest.description }}
                    </template>
<template v-if="career_interest.description">
                        <p class="py-1"><b>Title Specific Description:</b></p>
                        {{ career_interest.description }}
                    </template>
</p> -->
          <template v-if="career_interest.career_interest.description">
            <p class="py-1">
              <span><b>Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ career_interest.career_interest.description }}
            </p>
          </template>
          <template v-if="career_interest.description">
            <p class="py-1 mt-1">
              <span><b>Title Specific Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ career_interest.description }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
export default {
  name: 'CareerInterestCardComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    career_interest: {
      type: Object,
    },
  },
  data() {
    return {
      showAdditionalSources: false,
      isLike: this.career_interest.career_interest.like,
    };
  },
  methods: {
    detail_navigation(id, component) {
      specific_page_routing(this.$router, id, component)
    },
    
    additionalSources(e, id) {
      const div_id = 'additionalSources-cri-' + id;
      this.showAdditionalSources = !this.showAdditionalSources;
      document.querySelector('#' + div_id).classList.toggle('hidden');
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
