import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

const add_missing_info_store = {
  namespaced: true,
  state: {
    char_traits: null,
    entity_list: null,
    role: null,
  },
  mutations: {
    set_char_traits(state, data) {
      state.char_traits = data;
    },
    set_entity_list(state, data) {
      state.entity_list = data;
    },
    set_role(state, data) {
      state.role = data;
    },
  },
  actions: {
    get_char_traits_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.char_trait_api)
          .then((response) => {
            commit('set_char_traits', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    get_role_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.media_credits_options)
          .then((response) => {
            commit('set_role', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    get_entity_list_api({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(APIs.entity_list_api)
          .then((response) => {
            commit('set_entity_list', response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },

    add_entity_form_api(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(APIs.entity_form, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              console.error('Backend Error:', error.response.data.result);
            }
            reject(error.response.data.result);
          });
      });
    },
  },
};

export default add_missing_info_store;
