<template>
  <div
    @click="media_data_navigation(card_data.id)"
    class="flex flex-col cursor-pointer"
  >
    <div
      class="relative xl:h-[350px] sm:h-[285px] max-[640px]:h-[250px] bg-custom-gradient"
    >
      <div class="absolute right-3 top-3 z-10">
        <FavCardIconComponent 
          :card_data="card_data" 
          :related_media_param="related_media" />
      </div>
      <img
        :src="card_data.cover_image"
        :alt="card_data.title"
        class="absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto"
      />
    </div>
    <div class="flex justify-between items-center">
      <span class="text-xs text-[#9CA3AF] font-bold mt-3">{{
        card_data.origin
      }}</span>
      <span
        class="bg-blue px-3 text-white text-lg text-center rounded-full mt-2"
        >{{ card_data.media_type_display }}</span
      >
    </div>
    <p class="text-lg font-bold mt-3 break-words sm:h-[60px] sm:line-clamp-2">
      {{ card_data.title }}
    </p>
    <!-- <div class="flex justify-between items-center py-3">
      <template v-if="card_data.rating === 0">
        <span class="text-xs">Not Yet Rated</span>
      </template>
      <template v-else>
        <span class="text-xs">{{ card_data.rating }}/10</span>
      </template>
      <div class="flex items-center">
        <span @click.stop="set_like_dislike">
        
          <span
            :class="[
              like_response
                ? 'icon-redheart text-xl bg-[#f5f5f5] rounded-full p-2'
                : 'icon-heart text-xl bg-[#f5f5f5] rounded-full p-2',
            ]"
          >
          </span>
        </span>
        <span class="text-xs ml-3">{{ card_data.like_count }}</span>
      </div>
    </div> -->
    <div class="flex items-center">
      <!-- <span @click.stop="set_like_dislike">        
          <span
            :class="[
              like_response
                ? 'icon-redheart text-xl bg-[#f5f5f5] rounded-full p-2'
                : 'icon-heart text-xl bg-[#f5f5f5] rounded-full p-2',
            ]"
          >
          </span>
        </span> -->
      <!-- <span class="text-xs ml-3">{{ card_data.like_count }}</span> -->
    </div>
    <p
      class="text-sm text-[#9CA3AF] font-bold break-words sm:h-11 sm:line-clamp-2"
    >
      {{ card_data.genre.join(', ') }}
    </p>
  </div>
</template>

<script>
import FavCardIconComponent from '@/common_components/fav_card_icon/fav_card_icon_component.vue';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
export default {
  name: 'RelatedMediaCardComponent',
  components: {
    FavCardIconComponent,
  },
  props: {
    card_data: Object,
  },
  data() {
    return {
      related_media: "related_media",
      like_response: null,
      change_value: null,
    };
  },

  methods: {
    media_data_navigation(id) {
      media_page_navigation(this.$router, id, this.card_data.title, this.card_data.media_type);
    },
  },
};
</script>
